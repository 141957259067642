@use "../variables" as variable;

.cm-benefits {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    margin: 0 -8px;

    &__item {
        width: 50%;
        padding: 0 8px;
        margin-bottom: 20px;

        @include variable.media(576px, min) {
            width: 220px;
        }
    }

    &__image {
        width: 100%;
        height: 172px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px;
        background-color: #f6f6f6;

        img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
        }
    }

    &__name {
        color: variable.$primary;
        text-align: center;
        padding: 20px 0;
        font-size: 1rem;
        line-height: 1.5em;
        font-weight: 300;
    }
}