@use "../variables" as variable;

.quantity-wrapper {
    width: 130px;
    height: 36px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 12px;

    &__btns {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .screen-reader-text {
        display: none;
    }

    input {
        width: 100%;
        text-align: center;
        font-size: 1.25rem;
        font-family: inherit;
    }
    
}

.quantity-btn {
    width: 38px;
    height: 38px;
    border: 1px solid #dedede;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 300;
    color: #878787;

    &:hover {
        background-color: variable.$light;
        border-color: variable.$light;
        color: #ffffff;
    }
}




.woocommerce-variation-price {
    margin-bottom: 20px;
}

.woocommerce-variation-add-to-cart {
    display: flex;
    align-items: center;

    .quantity {
        margin-right: 30px;
    }
}

.variable-info {
    font-size: 1.875rem;
    letter-spacing: 0.02em;
    font-weight: 300;
    margin-bottom: 24px;
}


.price {
    font-size: 3.125rem;
    font-weight: 600;
    color: variable.$secondary;

    del {
        color: #878787;
        font-size: 1.5rem;
        font-weight: 400;
        margin-right: 12px;
    }

    ins {
        text-decoration: none;
    }
}