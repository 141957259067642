@use "../variables" as variable;

.cm-tabs {
    $tab : &;

    &__item {
        margin-bottom: 8px;
    }

    &__header {
        display: flex;
        align-items: center;
        padding: 20px 20px;
        background-color: #f6f6f6;
        transition: all .3s;
        cursor: pointer;
    }

    &__toggle {
        width: 14px;
        height: 14px;
        position: relative;
        margin-right: 10px;

        &::before, &::after {
            content: "";
            width: 14px;
            height: 2px;
            position: absolute;
            top: 5px;
            left: 0;
            background-color: variable.$secondary;
            transition: all .3s;
        }

        &::after {
            transform: rotate(90deg);
        }
    }

    &__title {
        font-size: 1rem;
        letter-spacing: 0.02em;
        font-weight: 300;
        line-height: 1.5em;

        @include variable.media(576px, min) {
            font-size: 1.125rem;
        }

        @include variable.media(992px, min) {
            font-size: 1.25rem;
        }
    }

    &__content {
        display: none;
        padding: 20px 0;
    }

    &__item--active {
        #{$tab} {
            &__header {
                background-image: linear-gradient(94deg, #c71066 0%, #ed6ea6 100%);
                color: #ffffff;
            }
    
            &__toggle {
                &::before, &::after {
                    background-color: #ffffff;
                }
    
                &::after {
                    transform: rotate(0);
                }
            }

            &__content {
                display: block;
            }   
        }
    }
    
}



@include variable.media(576px, min) {
    .cm-tabs {
        $tab : &;

        &__header {
            padding: 20px 40px;
        }

        &__toggle {
            margin-right: 30px;
        }
    }
}