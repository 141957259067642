@use "../variables" as variable;

.cm-footer {
    overflow: hidden;
    background-color: #f6f6f6;

    &__contact {
        border-bottom: 1px solid #b4b4b4;
        padding: 50px 0;
    }

    &__row {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .btn-contact {
            margin-bottom: 30px;
        }

        .btn {
            margin-bottom: 10px;
        }
    }

    &__info {
        padding-top: 70px;
    }

    &__nav {

    }

    &__socials {
        text-align: right;
    }

    &__copyright {
        color: #878787;
        font-size: 1rem;
        padding: 50px 0;

        strong {
            font-weight: 600;
        }

        a {
            &:hover {
                text-decoration: underline;
                color: variable.$light;
            }
        }
    }
}


.cm-footer-nav {
    @include variable.media(576px, min) {
        column-count: 2;
        column-gap: 40px;
    }

    @include variable.media(992px, min) {
        column-count: 3;
    }

    &__item {
        break-inside: avoid;
        margin-bottom: 60px;
    }

    &__title {
        font-size: 1rem;
        letter-spacing: 0.01em;
        font-weight: 700;
        margin-bottom: 20px;
    }
}



@include variable.media(576px, min) {
    .cm-footer {
        &__copyright {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
    }
}


@include variable.media(992px, min) {
    .cm-footer {
        &__row {
            flex-direction: row;
            align-items: center;
    
            .btn-contact {
                margin-right: auto;
                margin-bottom: 0;
    
                &:first-child {
                    margin-right: 60px;
                }
            }
    
            .btn {
                margin-left: 20px;
                margin-bottom: 0;
            }
        }
    }
}


@include variable.media(1200px, min) {

    .cm-footer {

        &__row {
            .btn-contact {
                &:first-child {
                    margin-right: 160px;
                }
            }
        }
    }
}


@include variable.media(1360px, min) {
    .cm-footer {
        &__info {
            display: flex;
        }

        &__nav {
            flex-grow: 1;
        }
    }
}