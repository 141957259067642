@use "../variables" as variable;

.cm-map-search {
    background-color: #f6f6f6;
    padding: 50px 0;
    margin-top: -60px;

    &__header {
        margin-bottom: 30px;
    }
}

.cm-place-map {
    position: relative;
    width: 100%;
    height: 520px;
    margin-bottom: 60px;
}