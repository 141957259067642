@use "../../variables" as variable;

.cmsp-service {
    padding-top: 40px;
    border-top: 1px solid variable.$grey;

    &__header {
        color: variable.$secondary;
        margin-bottom: 50px;
    }
}