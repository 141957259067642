@use "../variables" as variable;

::-webkit-input-placeholder { /* Edge */
    color: variable.$primary;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: variable.$primary;
}
::placeholder {
    color: variable.$primary;
}

input[type=text], input[type=email], input[type=tel], input[type=password], textarea {
    font-family: inherit;
    font-size: .875rem;
    line-height: 1.5em;
    padding: 14px 30px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    width: 100%;
    font-weight: 400;
    border-radius: 30px;
    color: variable.$primary;

    &:disabled {
        opacity: 0.5;
    }
}

input[type=checkbox] {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    width: 16px;
    height: 16px;
    border: 1px solid #000000;
    background-color: #ffffff;
 
    &::after {
        content: "";
        width: 8px;
        height: 8px;
        background-color: #000000;
        transform: scale(0);
        transition: all 0.3s;
    }

    &:checked {
        &::after {
            transform: scale(1);
        }
    }
}


input[type=radio] {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    width: 16px;
    height: 16px;
    border: 1px solid #000000;
    border-radius: 50%;
 
    &::after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #000000;
        transform: scale(0);
        transition: all 0.3s;
        border-radius: 50%;
    }

    &:checked {
        &::after {
            transform: scale(1);
        }
    }
}


input[type=submit] {
    display: inline-flex;
    font-family: inherit;
    font-size: .875rem;
    letter-spacing: 0.05em;
    font-weight: 700;
    text-transform: uppercase;
    padding: 16px 28px;
    border-radius: 50px;
    min-width: 176px;
    align-items: center;
    justify-content: center;
    background-color: variable.$secondary;
    color: #ffffff;
    cursor: pointer;

    &:disabled {
        background-color: variable.$light;
    }

    @include variable.media(575px, max) {
        width: 100%;
    }
}


.quick-form {

    &__footer {
        padding-top: 20px;
        display: flex;
        justify-content: center;
    }

    &--white {
        input[type=checkbox] {
            background-color: transparent;
            border-color: #ffffff;
        }

        input[type=submit] {
            background-color: #ffffff;
            color: variable.$primary;
        }
    }

    .form-field {
        margin-bottom: 10px;
    }    
}

.form-field {
    margin-bottom: 14px;
}

.form-info {
    font-size: 0.875rem;
    line-height: 1.375em;
}

.form-file {
    font-family: inherit;
    font-size: 0.875rem;
    line-height: 1.5em;
    padding: 14px 30px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    width: 100%;
    font-weight: 400;
    border-radius: 50px;
    color: #575756;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &::after {
        content: "";
        width: 18px;
        height: 18px;
        background-image: url(../../assets/icon-upload.svg);
        background-size: contain;
        background-repeat: no-repeat;
        transition: all .3s;
    }

    &:hover {
        &::after {
            transform: scale(1.1);
        }
    }

    input {
        display: none;
    }
}


.form-acceptance {
    font-size: .75rem;
    line-height: 1.25em;
    font-weight: 300;
    padding: 14px 0;

    b, strong {
        font-weight: 700;
    }

    a {
        text-decoration: underline;

        &:hover {
            color: variable.$light;
        }
    }

    label {
        display: flex;

        input {
            margin-right: 10px;
            margin-top: 2px;
        }
    }
}

.form-footer {
    display: flex;
    justify-content: flex-end;
}