
@use "../../variables" as variable;

.menu {
    display: flex;
    align-items: center;
    margin: 0 -20px;

    &__item {
        position: relative;

        & > a {
            padding: 30px 10px;
            font-size: 1em;
            font-weight: 300;
            display: flex;
            align-items: center;

            @include variable.media(1440px, min) {
                padding: 30px 20px;
            }
    
            svg {
                margin-left: 10px;
    
                path {
                    fill: variable.$primary;
                }
            }
        }

        &.current-menu-item > a {
            font-weight: 700;
            color: variable.$secondary;
        }

        &:hover {
            & > a {
                color: variable.$secondary;

                svg path {
                    fill: variable.$secondary;
                }
            }

            & > .submenu {
                display: block;
            }
        }
    }
}


.submenu {
    display: none;
    background-color: #ffffff;
    z-index: 9;
}



@include variable.media(1199px, max) {
    .menu {
        flex-direction: column;

        &__item {
            width: 100%;
            border-bottom: 1px solid #dedede;
            
            &.menu-item-has-children {
                &  > a {
                    pointer-events: none;
                }
            }

            & > a {
                width: 100%;
                padding: 20px;
                justify-content: center;
            }
        }   
    }

    .submenu {
        position: relative;
        background-color: #f6f6f6;;

        &__title {
            display: none;
        }   

        &__items {
            margin-bottom: 0 !important;
        }

        &__item {
            padding-left: 0 !important;
            
            a {
                display: block;
                text-align: center;
                padding: 10px 0;
                border-top: 1px solid #dedede;
            }
        }
    }
}


@include variable.media(1200px, min) {
    .submenu {
        position: absolute;
        top: 100%;
        left: -40px;
        box-shadow: 0 30px 30px rgba(0, 0, 0, 0.2);
        border: 1px solid #dedede;
        padding: 40px 60px 20px;

        &__row {
            display: flex;
            margin: 0 -15px;
        }

        &__col {
            width: 250px;
            padding: 0 15px;
        }

        &__title {
            font-size: 1rem;
            line-height: 1.5em;
            letter-spacing: 0.01em;
            font-weight: 700;
            color: #878787;
            margin-bottom: 20px;
        }
    }
}