@use "../variables" as variable;

.cm-price-list {
    font-size: 1rem;

    &__item {
        margin-bottom: 50px;
    }

    &__cat {
        font-weight: 700;
        margin-bottom: 24px;
    }

    &__field {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    &__name {
        font-weight: 300;
    }

    &__hr {
        flex-grow: 1;
        margin: 0 22px;
        height: 1px;
        background-color: variable.$grey;
    }

    &__value {
        min-width: max-content;
        display: flex;
        align-items: center;

        a {
            margin-left: 12px;
        }
    }

    &__price {
        font-weight: 600;
    }
}



@include variable.media(767px, max) {
    .cm-price-list {
        &__field {
            justify-content: space-between;
            border-bottom: 1px solid variable.$grey;
            padding-bottom: 14px;
        }

        &__hr {
            display: none;
        }

        &__name {
            padding-right: 30px;
        }
    }
}


@include variable.media(768px, min) {
    .cm-price-list {
        font-size: 1.125rem;
        letter-spacing: 0.02em;

    }
}