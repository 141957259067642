.grid-full {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
}


.grid {
    width: 90%;
    max-width: 1600px;
    margin: 0 auto;

    &--small {
        max-width: 1084px;
    }
}