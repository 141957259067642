@use "../../variables" as variable;

.cmh-hero {
    margin-bottom: 30px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ffffff;
        width: 100%;
        height: calc(50% - 30px);
    }


    &__box {
        position: relative;
    }

    &__slider {
        display: flex;
        overflow: hidden;
    }
}


@include variable.media(1200px, min) {
    .cmh-hero {
        &__row {
            display: flex;
            align-items: stretch;
            position: relative;
        }

        &__box {
            display: flex;
            width: 480px;
            min-width: 480px;
        }

        &__slider {
            width: calc(100% - 480px);
            order: 2;
        }
    }
}