@use "../../variables" as variable;


.cm-work-list {

    &__item {
        font-size: .875rem;
        //font-weight: 300;
        padding: 8px 0;

        &:nth-child(2n) {
            background-color: #f6f6f6;
        }
        
        &--header {
            font-size: .625rem;
            font-weight: 500;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            line-height: 1.5em;
            background-color: variable.$secondary;
            color: #ffffff;
            padding: 0;
        }
    }

    &__row {
        display: flex;
        align-items: center;
    }

    &__col {
        min-width: 160px;
        padding: 14px;
        text-align: center;
    }

    a {
        transition: all .3s;
        
        &:hover {
            color: variable.$secondary;
            //font-weight: 700;
        }
    }
}

table.cm-table-work {
    width: 100%;
    border-spacing: 0;

    th {
        background-color: variable.$secondary;
        color: #ffffff;
        font-size: .625rem;
        font-weight: 400;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        line-height: 1.5em;
        height: 50px;
        vertical-align: middle;
        text-align: center;
        padding:0 14px;

        &:first-child {
            text-align: left;
        }

        span {
            display: block;
            max-width: 140px;
        }
    }
    
    td {
        padding: 22px 14px;
        font-size: .875rem;
        font-weight: 300;
        text-align: center;

        &:first-child {
            text-align: left;
        }
    }

    tr {
        &:nth-child(2n+1) {
            background-color: #f6f6f6;
        }
    }
}


@include variable.media(991px, max) {
    .cm-work-list {
        &__item {
            padding: 16px 0;

            &--header {
                display: none;
            }
        }

        &__row {
            flex-direction: column;
            align-items: flex-start;
        }

        &__col {
            padding: 6px 14px;

            &::before {
                content: attr(data-title)":";
                display: inline-block;
                margin-right: 6px;
                font-weight: 500;
            }
        }
    }
}


@include variable.media(992px, min) {
    .cm-work-list {
        &__col {
            &:first-child {
                text-align: left;
            }

            &--position, &--type {
                width: 100%;
            }

            &--place, &--date {
                width: 160px;
            }
        }
    }
}