@use "../variables" as variable;

.cm-box-comment {
    border-radius: 5px;
    border: 1px solid variable.$secondary;
    padding: 22px 20px 22px;

    &__title {
        color: variable.$secondary;
        margin-bottom: 24px;
    }

    &__image {
        margin-bottom: 30px;
    }
   
}


@include variable.media(992px, min) {

    .cm-box-comment {
        padding: 22px 40px 32px;

        &__row {
            display: flex;
        }
        
        &__image {
            width: 150px;
            min-width: 150px;
            margin-right: 50px;
            margin-bottom: 0;
        }

        &__desc {
            flex-grow: 1;
        }
    }
}