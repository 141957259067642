@use "../variables" as variable;

.cm-blog-box {
    $box : &;
    display: block;
    width: 100%;
    position: relative;
    height: 268px;
    border-radius: variable.$box-radius;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background-image: linear-gradient(180deg, rgba(87, 87, 86, 0) 0%, #575756 100%);
    }

    &__img {
        @include variable.cover();
        object-fit: cover;
    }

    &__name {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 2;
        padding: 26px;
        color: #ffffff;
        transition: all .3s;
    }

    &__cover {
        @include variable.cover();
        @include variable.center();
        opacity: 0;
        transition: all .3s;
        pointer-events: none;
    }

    &:hover {
        #{$box} {

            &__name {
                opacity: 0;
            }

            &__cover {
                opacity: 1;
            }
        }
    }
}