@use "../../variables" as variable;

.cm-declaration {
    
    &__header {
        background-color: #F6F6F6;
        padding-top: 60px;
        margin-top: -60px;
        padding-bottom: 60px;
        margin-bottom: 80px;
    }

    &__title {
        margin-bottom: 50px;
        text-align: center;
    }

    &__item {
        display: none;

        &.active {
            display: block;
        }
    }

    &__desc {
        max-width: 1360px;
        margin: 0 auto 80px auto;
    }

    &__name {
        margin-bottom: 40px;
        text-align: center;
    }
}