@use "../variables" as variable;

html {
	font-size: 1rem;
}

body {
	font-family: variable.$font_primary;
	color: variable.$primary;
	font-size: 1rem;
	background-color: #ffffff;
}

main {
	overflow: hidden;
	padding-top: 136px;
	color: variable.$primary;

	@include variable.media(1199px, max) {
		padding-top: 80px;
	}
}


.center {
	text-align: center;
}


.relative {
	position: relative;
}


.text {
	font-weight: 300;
	font-size: 1em;
	line-height: 1.5em;
	text-transform: none;
	text-decoration: none;

	@include variable.media(576px, min) {
		line-height: 1.875em;
	}

	b, strong {
		font-weight: 700;
	}
	

	h1, h2, h3, h4, h5, h6 {
		font-weight: 300;
		margin-bottom: 40px;
		//line-height: 1.125em;
	}

	h1 {
		font-size: 1.5rem;
	}

	h2 {
		font-size: 2.25rem;
	}

	h3 {
		font-size: 1.125rem;
		font-weight: 300;
	}

	h4 {
		font-size: 1.5rem;
	}

	h5 {
		font-size: 1.25rem;
	}

	h6 {
		font-size: 1rem;
	}

	p {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ol {
		list-style: decimal;
		padding-left: 18px;
		margin-bottom: 20px;

		& > li {
			margin-bottom: 6px;
		}
	}

	ul {
		line-height: 2em;
		margin-bottom: 20px;

		li {
			position: relative;
			padding-left: 20px;

			&::before {
				content: "";
				width: 5px;
				height: 8px;
				position: absolute;
				top:11px;
				left: 0;
				background-image: url(../../assets/list.svg);
			}
		}
	}

	img {
		height: auto;
	}

	a {
		&:hover {
			color: variable.$secondary;
		}
	}

	hr {
		display: block;
		width: 100%;
		border-bottom: 1px solid #d0dfc0;
		margin-bottom: 40px;
		margin-top: 40px;
	}


	iframe {
		display: block;
		max-width: 100%;
		margin: 0 auto;
		margin-bottom: 40px;
	}

	a[href^="tel:"], a[href^="mailto:"], a[href^="https://www.google.com/maps/"] {
		display: flex;
		align-items: center;
		color: variable.$primary;

		&::before {
			content: "";
			width: 22px;
			height: 22px;
			margin-right: 16px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		&:hover {
			color: variable.$light;
			text-decoration: underline;
		}
	}

	a[href^="tel:"]::before {
		background-image: url(../../assets/link-tel.svg);
	}

	a[href^="mailto:"]::before {
		background-image: url(../../assets/link-mailto.svg);
	}

	a[href^="https://www.google.com/maps/"]::before {
		background-image: url(../../assets/link-place.svg);
	}

	&--work {
		p > strong {
			font-size: 1rem;
			line-height: 1.5em;
			font-weight: 600;
			color: variable.$secondary;

			@include variable.media(576px, min) {
				font-size: 1.125rem;
			}

			@include variable.media(1200px, min) {
				font-size: 1.5rem;
			}
		}
	}
}



a,
button {
	cursor: pointer;
}


.title {
	line-height: 1.5em;
	font-weight: 600;

	&--1 {
		font-size: 1.5rem;
		line-height: 1.125em;

		@include variable.media(576px, min) {
			font-size: 2rem;
		}

		@include variable.media(768px, min) {
			font-size: 2.375rem;
		}

		@include variable.media(1260px, min) {
			font-size: 3.125rem;
		}
	}

	&--2 {
		font-size: 1rem;

		@include variable.media(576px, min) {
			font-size: 1.125rem;
		}

		@include variable.media(1200px, min) {
			font-size: 1.5rem;
		}
	}

	&--3 {
		font-size: 1.25rem;
		line-height: 1.15em;

		@include variable.media(576px, min) {
			font-size: 1.5rem;
		}

		@include variable.media(1440px, min) {
			font-size: 1.875rem;
		}
	}

	&--light {
		font-weight: 300;
	}

	&--secondary {
		color: variable.$secondary;
	}
}


// section {
// 	overflow: hidden;
// }

.section-margin {
	padding-bottom: 40px;

	@include variable.media(576px, min) {
		padding-bottom: 60px;
	}

	@include variable.media(992px, min) {
		padding-bottom: 100px;
	}
}

.section-white {
	background-color: #ffffff;
}

.section-space {
	padding-top: 50px;

	@include variable.media(576px, min) {
		padding-top: 100px;
	}
}

.yith-wcwl-add-to-wishlist {
	padding-top: 20px;
}