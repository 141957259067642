@use "../variables" as variable;


.cm-post-list {
    $list: &;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -14px;

    &__item {
        display: flex;
        width: 100%;
        padding: 0 14px;
        margin-bottom: 28px;

        @include variable.media(768px, min) {
            width: 50%;
        }

        @include variable.media(992px, min) {
            width: 33.33%;
        }

        @include variable.media(1360px, min) {
            width: 25%;
        }
    }

    &--3 {
        #{$list} {
            &__item {
                width: 100%;

                @include variable.media(768px, min) {
                    width: 50%;
                }

                @include variable.media(1200px, min) {
                    width: 33.33%;
                }
            }
        }
    }

    &--2 {
        #{$list} {
            &__item {
                width: 100%;

                @include variable.media(992px, min) {
                    width: 50%;
                }

            }
        }
    }
}