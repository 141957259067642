@use "../variables" as variable;

.cm-product-category-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;

    &__item {
        display: flex;
        width: 100%;
        padding: 0 6px;
        margin-bottom: 6px;

        @include variable.media(576px, min) {
            width: 50%;
            margin-bottom: 12px;
        }

        @include variable.media(1200px, min) {
            width: 25%;
        }
    }
}


.cm-cat-box {
    $box: &;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    position: relative;

    &__title {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: 0.02em;
        font-weight: 600;
        margin-bottom: 4px;
    }

    &__icon {
        margin-right: 18px;
    }

    &__arrow {
        position: absolute;
        right: 20px;
        bottom: 10px;
        opacity: 0;

        svg {
            fill: variable.$secondary;
        }
    }


    &__info {
        font-size: .875rem;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        font-weight: 700;
    }


    &:hover {
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);

        #{$box} {
            &__arrow {
                opacity: 1;
            }

            &__title {
                color: variable.$secondary;
            }
        }
    }

    &--green {
        background-color: #97bf26;

        &::after {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            border-radius: 5px;
            border: 1px solid #b1e327;
        }

        #{$box} {
            &__arrow svg {
                fill: #ffffff;
            }

            &__title {
                color: #ffffff;
            }
        }

        &:hover {
            #{$box} {
                &__title {
                    color: #ffffff;
                }
            }
        }
    }


    &--all {
        background-image: linear-gradient(110deg, #c71066 0%, #ed6ea6 100%);
        color: #ffffff;

        @include variable.media(575px, max) {
            justify-content: center;
        }

        #{$box} {
            &__arrow svg {
                fill: #ffffff;
            }
        }
    }

}



@include variable.media(768px, min) {
    .cm-cat-box {
        $box: &;
        padding: 30px;

        &__title {
            font-size: 1.25rem;
            max-width: 190px;
        }

        &__icon {
            margin-right: 28px;
        }

        &__arrow {
            right: 40px;
            bottom: 30px;
        }

        &__info {
            max-width: 180px;
        }
    }
}