@use "../../variables" as variable;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.quantity {
    display: flex;
    align-items: stretch;

    &.hidden {
        display: none;
    }

    label {
        display: none;
    }

    &__btns {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    input {
        width: 50px;
        height: 46px;
        text-align: center;
        border: 1px solid #BBBBBB;
        appearance: none;
        margin-right: 6px;
    }
}

.btn-quantity {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid #BBBBBB;

    &:hover {
        background-color: #bbbbbb;
        //border-color: variable.$light;
    }

    &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
    }

    &--inc {
        &::after {
            border-width: 0 4px 4px 4px;
            border-color: transparent transparent #bbbbbb transparent
        }

        &:hover {
            &::after {
                border-color: transparent transparent #ffffff transparent
            }
        }
    }

    &--dec {
        &::after {
            border-width: 4px 4px 0 4px;
            border-color: #bbbbbb transparent transparent transparent;
        }

        &:hover {
            &::after {
                border-color: #ffffff transparent transparent transparent;
            }
        }
    }
}