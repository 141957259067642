@use "../variables" as variable;

.search-form {
    display: block;
    position: relative;

    &__btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 51px;
        height: 51px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 14px;
            transition: all .3s;

            path {
                fill: #878787;
            }
        }

        &:hover {
            svg {
                transform: scale(1.2);
            }
        }
    }
}