@use "../../variables" as variable;

.cmsp-steps {
    counter-reset: item;

    &__item {
        counter-increment: item;
        display: flex;
        margin-bottom: 40px;
        
        &::before {
            content: counter(item);
            width: 32px;
            min-width: 32px;
            height: 32px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: variable.$light;
            color: #ffffff;
            font-size: 1rem;
            font-weight: 600;
            margin-right: 22px;
            border-radius: 50%;
        }
    }

    &__content {
        padding-top: 4px;
    }

    &__title {
        font-size: 1.25rem;
        letter-spacing: 0.02em;
        font-weight: 600;
        color: variable.$secondary;
        margin-bottom: 12px;
    }
}