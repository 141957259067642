@use "../../variables" as variable;

.cart-info-box {
    background-color: #F6F6F6;
    margin-bottom: 20px;
    width: 100%;

    &__header {
        padding: 20px 20px;
        border-bottom: 2px solid #fff;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    &__content {
        padding: 20px 20px 30px;
    }

    &__text {
        padding: 10px 0 20px;
        font-size: 1rem;
        line-height: 1.5em;
        color: #BBBBBB;
    }

    button, a {
        width: 100%;
    }
}

#coupon_code {
    margin-bottom: 10px;
}


@include variable.media(576px, min) {
    .cart-info-box {
        &__header {
            padding: 20px 40px;
        }

        &__content {
            padding: 20px 40px 30px;
        }
    }
}