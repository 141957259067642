@use "../variables" as variable;


.cm-post-related {
    padding: 30px 0;
}

.post-list {
    &__item {
        margin-bottom: 10px;
    }
}

.cm-post-box-s {
    $box: &;
    display: flex;
    align-items: flex-start;

    &__image {
        width: 120px;
        min-width: 120px;
        height: 80px;
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        img {
            @include variable.cover();
            object-fit: cover;
        }
    }


    &__desc {
        flex-grow: 1;
        padding-left: 16px;
    }

    &__tags {
        font-size: .875rem;
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: 6px;
    }

    &__title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5em;
    }

    &:hover {
        color: variable.$secondary;
    }
}


@include variable.media(576px, min) {
    .cm-post-box-s {
        &__image {
            width: 160px;
            min-width: 160px;
            height: 120px;
        }

        &__desc {
            padding-left: 20px;
        }

        &__tags {
            margin-bottom: 20px;
        }
    }
}