*,
::after,
::before {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

img,
article,
main,
aside,
details,
figcaption,
figure,
footer,
header,
nav {
  display: block;
}

address {
  font-style: inherit;
}

img {
  max-width: 100%;
}

ol,
ul {
  list-style: none;
}

li:empty,
p:empty {
  display: none;
}

textarea,
select,
input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  background: transparent;
}

strong {
  font-weight: bold;
}

a {
  text-decoration: none;
  color: inherit;
}

br {
  content: "";
  display: block;
}

:focus,
:active {
  outline: none;
}

/*
* Sets element media query
*/
/*
* Fills relative parent width and height
*/
/*
* Centers element by type
*/
html {
  font-size: 1rem;
}

body {
  font-family: "Montserrat", sans-serif;
  color: #575756;
  font-size: 1rem;
  background-color: #ffffff;
}

main {
  overflow: hidden;
  padding-top: 136px;
  color: #575756;
}
@media screen and (max-width: 1199px) {
  main {
    padding-top: 80px;
  }
}

.center {
  text-align: center;
}

.relative {
  position: relative;
}

.text {
  font-weight: 300;
  font-size: 1em;
  line-height: 1.5em;
  text-transform: none;
  text-decoration: none;
}
@media screen and (min-width: 576px) {
  .text {
    line-height: 1.875em;
  }
}
.text b, .text strong {
  font-weight: 700;
}
.text h1, .text h2, .text h3, .text h4, .text h5, .text h6 {
  font-weight: 300;
  margin-bottom: 40px;
}
.text h1 {
  font-size: 1.5rem;
}
.text h2 {
  font-size: 2.25rem;
}
.text h3 {
  font-size: 1.125rem;
  font-weight: 300;
}
.text h4 {
  font-size: 1.5rem;
}
.text h5 {
  font-size: 1.25rem;
}
.text h6 {
  font-size: 1rem;
}
.text p {
  margin-bottom: 20px;
}
.text p:last-child {
  margin-bottom: 0;
}
.text ol {
  list-style: decimal;
  padding-left: 18px;
  margin-bottom: 20px;
}
.text ol > li {
  margin-bottom: 6px;
}
.text ul {
  line-height: 2em;
  margin-bottom: 20px;
}
.text ul li {
  position: relative;
  padding-left: 20px;
}
.text ul li::before {
  content: "";
  width: 5px;
  height: 8px;
  position: absolute;
  top: 11px;
  left: 0;
  background-image: url(../../assets/list.svg);
}
.text img {
  height: auto;
}
.text a:hover {
  color: #c71066;
}
.text hr {
  display: block;
  width: 100%;
  border-bottom: 1px solid #d0dfc0;
  margin-bottom: 40px;
  margin-top: 40px;
}
.text iframe {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
}
.text a[href^="tel:"], .text a[href^="mailto:"], .text a[href^="https://www.google.com/maps/"] {
  display: flex;
  align-items: center;
  color: #575756;
}
.text a[href^="tel:"]::before, .text a[href^="mailto:"]::before, .text a[href^="https://www.google.com/maps/"]::before {
  content: "";
  width: 22px;
  height: 22px;
  margin-right: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.text a[href^="tel:"]:hover, .text a[href^="mailto:"]:hover, .text a[href^="https://www.google.com/maps/"]:hover {
  color: #ed6ea6;
  text-decoration: underline;
}
.text a[href^="tel:"]::before {
  background-image: url(../../assets/link-tel.svg);
}
.text a[href^="mailto:"]::before {
  background-image: url(../../assets/link-mailto.svg);
}
.text a[href^="https://www.google.com/maps/"]::before {
  background-image: url(../../assets/link-place.svg);
}
.text--work p > strong {
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 600;
  color: #c71066;
}
@media screen and (min-width: 576px) {
  .text--work p > strong {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 1200px) {
  .text--work p > strong {
    font-size: 1.5rem;
  }
}

a,
button {
  cursor: pointer;
}

.title {
  line-height: 1.5em;
  font-weight: 600;
}
.title--1 {
  font-size: 1.5rem;
  line-height: 1.125em;
}
@media screen and (min-width: 576px) {
  .title--1 {
    font-size: 2rem;
  }
}
@media screen and (min-width: 768px) {
  .title--1 {
    font-size: 2.375rem;
  }
}
@media screen and (min-width: 1260px) {
  .title--1 {
    font-size: 3.125rem;
  }
}
.title--2 {
  font-size: 1rem;
}
@media screen and (min-width: 576px) {
  .title--2 {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 1200px) {
  .title--2 {
    font-size: 1.5rem;
  }
}
.title--3 {
  font-size: 1.25rem;
  line-height: 1.15em;
}
@media screen and (min-width: 576px) {
  .title--3 {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1440px) {
  .title--3 {
    font-size: 1.875rem;
  }
}
.title--light {
  font-weight: 300;
}
.title--secondary {
  color: #c71066;
}

.section-margin {
  padding-bottom: 40px;
}
@media screen and (min-width: 576px) {
  .section-margin {
    padding-bottom: 60px;
  }
}
@media screen and (min-width: 992px) {
  .section-margin {
    padding-bottom: 100px;
  }
}

.section-white {
  background-color: #ffffff;
}

.section-space {
  padding-top: 50px;
}
@media screen and (min-width: 576px) {
  .section-space {
    padding-top: 100px;
  }
}

.yith-wcwl-add-to-wishlist {
  padding-top: 20px;
}

.grid-full {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

.grid {
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
}
.grid--small {
  max-width: 1084px;
}

.btn-contact {
  display: inline-flex;
  align-items: center;
}
.btn-contact img {
  margin-right: 1rem;
}
.btn-contact__info {
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 4px;
}
.btn-contact__value {
  font-size: 1.125rem;
  font-weight: 700;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  font-weight: 700;
  text-transform: uppercase;
  padding: 14px 28px;
  border-radius: 50px;
}
.btn svg {
  margin-right: 16px;
}
.btn--1 {
  background-color: #c71066;
  color: #ffffff;
}
.btn--1:hover {
  background-color: #ed6ea6;
}
.btn--2 {
  border: 1px solid #c71066;
}
.btn--2:hover {
  background-color: #ed6ea6;
  color: #ffffff;
  border-color: #ed6ea6;
}
.btn--2:hover svg path {
  fill: #ffffff;
}
.btn--white {
  background-color: #ffffff;
  color: #575756;
}
.btn--white:hover {
  background-color: #ed6ea6;
  color: #ffffff;
}

.btn-2 {
  font-size: 1rem;
  font-weight: 300;
  padding: 10px 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 50px;
}
.btn-2--white {
  border-color: #ffffff;
  color: #ffffff;
}
.btn-2:hover {
  color: #ffffff;
  background-color: #ed6ea6;
}

.btn-action {
  font-size: 1rem;
  font-weight: 300;
  padding: 10px 20px;
  display: inline-flex;
  align-items: center;
  border: 1px solid #c71066;
  border-radius: 50px;
}
.btn-action svg, .btn-action img {
  margin-right: 12px;
}
.btn-action svg path {
  fill: #c71066;
}
.btn-action:hover {
  background-color: #ed6ea6;
  border-color: #ed6ea6;
  color: #ffffff;
}
.btn-action:hover svg path {
  fill: #ffffff;
}

.btn-search {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  width: 42px;
  height: 42px;
  border: 1px solid #dedede;
  border-radius: 50%;
}
.btn-search svg {
  width: 14px;
  height: auto;
}
.btn-search svg path {
  fill: #575756;
}
.btn-search:hover {
  background-color: #ed6ea6;
  border-color: #ed6ea6;
}
.btn-search:hover svg path {
  fill: #ffffff;
}

.btn-shop {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  width: 42px;
  height: 42px;
  border: 1px solid #dedede;
  border-radius: 50%;
  position: relative;
}
.btn-shop__value {
  position: absolute;
  bottom: 26px;
  left: 26px;
  font-size: 10px;
  background: #c71066;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.btn-shop svg {
  width: 14px;
  height: auto;
}
.btn-shop svg path {
  fill: #575756;
}
.btn-shop:hover {
  background-color: #ed6ea6;
  border-color: #ed6ea6;
}
.btn-shop:hover svg path {
  fill: #ffffff;
}

.btn-nav {
  width: 48px;
  min-width: 48px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DEDEDE;
  border-radius: 50%;
}
.btn-nav svg, .btn-nav img {
  width: 18px;
}
.btn-nav--prev svg, .btn-nav--prev img {
  transform: rotate(180deg);
}
.btn-nav.disable svg {
  fill: #DEDEDE;
}

a.btn-nav:hover {
  background-color: #c71066;
}
a.btn-nav:hover svg {
  fill: #ffffff;
}

.btn-up {
  width: 38px;
  height: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DEDEDE;
  border-radius: 50%;
  position: fixed;
  right: 6px;
  bottom: 10px;
  background-color: #ffffff;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
@media screen and (min-width: 768px) {
  .btn-up {
    width: 44px;
    height: 44px;
    right: 6px;
    bottom: 20px;
  }
}
@media screen and (min-width: 1440px) {
  .btn-up {
    right: 30px;
    bottom: 30px;
  }
}
.btn-up.active {
  opacity: 1;
  pointer-events: all;
}

@media screen and (max-width: 575px) {
  .btn-action svg, .btn-action img {
    margin-right: 0;
  }
  .btn-action span {
    display: none;
  }
}
::-webkit-input-placeholder { /* Edge */
  color: #575756;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #575756;
}

::placeholder {
  color: #575756;
}

input[type=text], input[type=email], input[type=tel], input[type=password], textarea {
  font-family: inherit;
  font-size: 0.875rem;
  line-height: 1.5em;
  padding: 14px 30px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  width: 100%;
  font-weight: 400;
  border-radius: 30px;
  color: #575756;
}
input[type=text]:disabled, input[type=email]:disabled, input[type=tel]:disabled, input[type=password]:disabled, textarea:disabled {
  opacity: 0.5;
}

input[type=checkbox] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  width: 16px;
  height: 16px;
  border: 1px solid #000000;
  background-color: #ffffff;
}
input[type=checkbox]::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #000000;
  transform: scale(0);
  transition: all 0.3s;
}
input[type=checkbox]:checked::after {
  transform: scale(1);
}

input[type=radio] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  width: 16px;
  height: 16px;
  border: 1px solid #000000;
  border-radius: 50%;
}
input[type=radio]::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #000000;
  transform: scale(0);
  transition: all 0.3s;
  border-radius: 50%;
}
input[type=radio]:checked::after {
  transform: scale(1);
}

input[type=submit] {
  display: inline-flex;
  font-family: inherit;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  font-weight: 700;
  text-transform: uppercase;
  padding: 16px 28px;
  border-radius: 50px;
  min-width: 176px;
  align-items: center;
  justify-content: center;
  background-color: #c71066;
  color: #ffffff;
  cursor: pointer;
}
input[type=submit]:disabled {
  background-color: #ed6ea6;
}
@media screen and (max-width: 575px) {
  input[type=submit] {
    width: 100%;
  }
}

.quick-form__footer {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.quick-form--white input[type=checkbox] {
  background-color: transparent;
  border-color: #ffffff;
}
.quick-form--white input[type=submit] {
  background-color: #ffffff;
  color: #575756;
}
.quick-form .form-field {
  margin-bottom: 10px;
}

.form-field {
  margin-bottom: 14px;
}

.form-info {
  font-size: 0.875rem;
  line-height: 1.375em;
}

.form-file {
  font-family: inherit;
  font-size: 0.875rem;
  line-height: 1.5em;
  padding: 14px 30px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  width: 100%;
  font-weight: 400;
  border-radius: 50px;
  color: #575756;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.form-file::after {
  content: "";
  width: 18px;
  height: 18px;
  background-image: url(../../assets/icon-upload.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s;
}
.form-file:hover::after {
  transform: scale(1.1);
}
.form-file input {
  display: none;
}

.form-acceptance {
  font-size: 0.75rem;
  line-height: 1.25em;
  font-weight: 300;
  padding: 14px 0;
}
.form-acceptance b, .form-acceptance strong {
  font-weight: 700;
}
.form-acceptance a {
  text-decoration: underline;
}
.form-acceptance a:hover {
  color: #ed6ea6;
}
.form-acceptance label {
  display: flex;
}
.form-acceptance label input {
  margin-right: 10px;
  margin-top: 2px;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.section-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
}
@media screen and (min-width: 576px) {
  .section-header {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 575px) {
  .section-header__title {
    width: 100%;
    margin-bottom: 12px;
  }
}
.select2-container--default .select2-selection--single {
  height: 51px;
  border-radius: 23px;
  border: 1px solid #dedede;
  display: flex;
  align-items: center;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 51px;
  position: absolute;
  top: 0;
  right: 20px;
  width: 20px;
  background-image: url(../../assets/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 30px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  font-size: 0.875rem;
  color: #575756;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #c71066;
}

.select2-dropdown {
  border: 1px solid #dedede;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}

.select2-results__option {
  padding: 10px 30px;
  font-size: 0.875rem;
}

.select2-container--default .select2-results__option--disabled {
  display: none;
}

select {
  width: 100%;
  max-width: 400px;
  height: 50px;
  border: 1px solid #bbbbbb;
  border-radius: 50px;
  padding: 0 30px;
  background-image: url(../../assets/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: center right 30px;
  background-size: 10px 8px;
}

.scrollbar-outer {
  height: 380px;
}

.scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
  width: 5px;
}

.scrollbar-outer > .scroll-element .scroll-element_track {
  width: 1px;
  background-color: #dedede;
  left: 2px;
}

.scrollbar-outer > .scroll-element.scroll-y .scroll-bar {
  background-color: #c71066;
  cursor: pointer;
}

.variations {
  margin-bottom: 30px;
}
.variations__title {
  font-size: 0.875rem;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .variations__item {
    display: flex;
    align-items: center;
  }
  .variations__title {
    margin-right: 30px;
    margin-bottom: 0;
  }
}
.quantity-wrapper {
  width: 130px;
  height: 36px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 12px;
}
.quantity-wrapper__btns {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quantity-wrapper .screen-reader-text {
  display: none;
}
.quantity-wrapper input {
  width: 100%;
  text-align: center;
  font-size: 1.25rem;
  font-family: inherit;
}

.quantity-btn {
  width: 38px;
  height: 38px;
  border: 1px solid #dedede;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 300;
  color: #878787;
}
.quantity-btn:hover {
  background-color: #ed6ea6;
  border-color: #ed6ea6;
  color: #ffffff;
}

.woocommerce-variation-price {
  margin-bottom: 20px;
}

.woocommerce-variation-add-to-cart {
  display: flex;
  align-items: center;
}
.woocommerce-variation-add-to-cart .quantity {
  margin-right: 30px;
}

.variable-info {
  font-size: 1.875rem;
  letter-spacing: 0.02em;
  font-weight: 300;
  margin-bottom: 24px;
}

.price {
  font-size: 3.125rem;
  font-weight: 600;
  color: #c71066;
}
.price del {
  color: #878787;
  font-size: 1.5rem;
  font-weight: 400;
  margin-right: 12px;
}
.price ins {
  text-decoration: none;
}

.social-media {
  display: flex;
  justify-content: flex-end;
  margin: 0 -5px;
}
.social-media__item {
  padding: 0 5px;
}
.social-media__link {
  width: 62px;
  height: 62px;
  min-width: 62px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #c71066;
}
.social-media__link:hover {
  background-color: #ed6ea6;
  border-color: #ed6ea6;
}
.social-media__link:hover svg path {
  fill: #ffffff;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

.pagination-pages {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 300;
  color: #878787;
}
@media screen and (min-width: 576px) {
  .pagination-pages {
    padding: 0 30px;
  }
}
.pagination-pages__item a {
  padding: 12px;
}
.pagination-pages__item a:hover {
  color: #c71066;
}
.pagination-pages__item .current {
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #ffffff;
  background-color: #c71066;
}

.search-form {
  display: block;
  position: relative;
}
.search-form__btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 51px;
  height: 51px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.search-form__btn svg {
  width: 14px;
  transition: all 0.3s;
}
.search-form__btn svg path {
  fill: #878787;
}
.search-form__btn:hover svg {
  transform: scale(1.2);
}

.review-rate {
  display: flex;
  align-items: center;
  justify-content: center;
}
.review-rate__item {
  padding: 0 3px;
}

.cm-video-iframe {
  display: block;
  position: relative;
  padding-bottom: 56.4%;
  width: 100%;
}
.cm-video-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cm-video-file {
  display: block;
  position: relative;
  padding-bottom: 56.4%;
  width: 100%;
}
.cm-video-file video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cm-compare-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}
.cm-compare-list__item {
  width: 100%;
  padding: 0 20px;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .cm-compare-list__item {
    width: 50%;
  }
}

.cm-compare-box__footer {
  padding: 10px 0;
  text-align: center;
}

.cd-image-container {
  position: relative;
  width: fit-content;
  max-width: 640px;
  border-radius: 8px;
  overflow: hidden;
}
.cd-image-container img {
  display: block;
}

.cd-image-label {
  font-size: 0.875rem;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #ffffff;
  padding: 20px 30px;
  transition: all 0.3s;
  width: max-content;
  color: #ffffff;
}

.is-visible .cd-image-label {
  opacity: 1;
}

.cd-resize-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  overflow: hidden;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-right: 1px solid #c71066;
}
.cd-resize-img img {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: auto;
  max-width: none;
}
.cd-resize-img .cd-image-label {
  right: auto;
  left: 0;
}

.is-visible .cd-resize-img {
  width: 50%;
  /* bounce in animation of the modified image */
  animation: cd-bounce-in 0.7s;
}

@-webkit-keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 55%;
  }
  100% {
    width: 50%;
  }
}
@-moz-keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 55%;
  }
  100% {
    width: 50%;
  }
}
@keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 55%;
  }
  100% {
    width: 50%;
  }
}
.cd-handle {
  position: absolute;
  height: 64px;
  width: 64px;
  left: 50%;
  top: 50%;
  margin-left: -32px;
  margin-top: -32px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-position: center;
  background-repeat: no-repeat;
  cursor: move;
  opacity: 0;
  transform: translate3d(0, 0, 0) scale(0);
  padding: 10px;
}
.cd-handle:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.cd-handle.draggable {
  /* change background color when element is active */
  background-color: rgba(255, 255, 255, 0.5);
}

.is-visible .cd-handle {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
  transition: transform 0.3s 0.7s, opacity 0s 0.7s;
}

.wpml-ls-legacy-dropdown {
  position: relative;
  min-width: 140px;
  border: 1px solid #DEDEDE;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 0.75rem;
}
.wpml-ls-legacy-dropdown:hover {
  border-radius: 20px 20px 0 0;
}
.wpml-ls-legacy-dropdown:hover .wpml-ls-sub-menu {
  display: block;
}
.wpml-ls-legacy-dropdown a {
  display: flex;
  align-items: center;
}
.wpml-ls-legacy-dropdown a img {
  margin-right: 8px;
  border-radius: 4px;
}
.wpml-ls-legacy-dropdown a.wpml-ls-item-toggle::after {
  content: "";
  background-image: url(../../assets/icon-expand.svg);
  width: 16px;
  height: 16px;
  background-size: contain;
  background-position: center;
  margin-left: auto;
}

.wpml-ls-sub-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: -1px;
  width: calc(100% + 2px);
  border: 1px solid #DEDEDE;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}
.wpml-ls-sub-menu a {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
}
.wpml-ls-sub-menu a:hover {
  background-color: #f6f6f6;
}

@media screen and (max-width: 575px) {
  .wpml-ls-legacy-dropdown {
    min-width: 80px;
    padding: 10px 16px;
  }
  .wpml-ls-legacy-dropdown .wpml-ls-native {
    display: none;
  }
  .wpml-ls-sub-menu a {
    padding: 10px 16px;
  }
}
.cm-box-visit {
  padding: 30px 20px;
  background-color: #c71066;
  background-image: linear-gradient(155deg, #c71066 0%, #ed6ea6 100%);
  color: #ffffff;
}
.cm-box-visit__img {
  max-width: 50px;
  margin-bottom: 16px;
}
.cm-box-visit__title {
  margin-bottom: 20px;
}
.cm-box-visit__text {
  font-size: 0.875em;
  line-height: 1.75em;
  font-weight: 300;
  margin-bottom: 30px;
}
.cm-box-visit__action {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.cm-box-visit__action a {
  margin: 0 5px 5px;
}
.cm-box-visit__info {
  padding-top: 30px;
  font-size: 0.75rem;
  line-height: 1.5em;
}

@media screen and (max-width: 575px) {
  .cm-box-visit__action {
    flex-wrap: wrap;
  }
  .cm-box-visit__action a {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 576px) {
  .cm-box-visit {
    padding: 50px 40px;
  }
  .cm-box-visit__img {
    max-width: 100%;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1440px) {
  .cm-box-visit {
    padding: 50px 60px;
  }
}
.cm-box-desc__header {
  margin-bottom: 40px;
}
.cm-box-desc__title {
  color: #c71066;
  margin-bottom: 16px;
}
.cm-box-desc__title--dark {
  color: #575756;
}
.cm-box-desc__info {
  font-size: 1.125rem;
  line-height: 1.25em;
  font-weight: 300;
  color: #c71066;
}
.cm-box-desc__info--dark {
  color: #575756;
}
.cm-box-desc__text {
  margin-bottom: 40px;
}
.cm-box-desc__action {
  display: flex;
  margin: 0 -10px;
}
.cm-box-desc__action a, .cm-box-desc__action button {
  margin: 0 10px;
}

@media screen and (max-width: 575px) {
  .cm-box-desc__action {
    flex-wrap: wrap;
  }
  .cm-box-desc__action a {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .cm-box-desc__info {
    font-size: 1.875rem;
  }
}
.cm-box-info {
  border-radius: 5px;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  padding: 20px;
}
@media screen and (min-width: 1440px) {
  .cm-box-info {
    padding: 30px 40px;
  }
}
.cm-box-info--dark {
  background-color: #f6f6f6;
}
.cm-box-info__title {
  color: #c71066;
  margin-bottom: 26px;
}
.cm-box-info__text {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.875em;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0.01em;
}
.cm-box-info__text p {
  margin-bottom: 20px;
}
.cm-box-info__text p:last-child {
  margin-bottom: 0;
}
.cm-box-info__text b, .cm-box-info__text strong {
  font-weight: 700;
}
.cm-box-info__text a.active {
  color: #c71066;
  font-weight: 600;
}
.cm-box-info__text a:hover {
  color: #ed6ea6;
}
.cm-box-info__text a[href^="tel:"], .cm-box-info__text a[href^="mailto:"], .cm-box-info__text a[href^="https://www.google.com/maps/"] {
  display: flex;
  align-items: center;
  margin: 5px 0;
  color: #575756;
}
.cm-box-info__text a[href^="tel:"]::before, .cm-box-info__text a[href^="mailto:"]::before, .cm-box-info__text a[href^="https://www.google.com/maps/"]::before {
  content: "";
  width: 16px;
  min-width: 16px;
  height: 18px;
  margin-right: 12px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.cm-box-info__text a[href^="tel:"]:hover, .cm-box-info__text a[href^="mailto:"]:hover, .cm-box-info__text a[href^="https://www.google.com/maps/"]:hover {
  color: #ed6ea6;
  text-decoration: underline;
}
.cm-box-info__text a[href^="tel:"]::before {
  background-image: url(../../assets/link-tel.svg);
}
.cm-box-info__text a[href^="mailto:"]::before {
  background-image: url(../../assets/link-mailto.svg);
}
.cm-box-info__text a[href^="https://www.google.com/maps/"]::before {
  background-image: url(../../assets/link-place.svg);
}
.cm-box-info__text hr {
  border-top: 1px solid #dedede;
  margin-bottom: 20px;
}

.cm-open-list {
  font-size: 1rem;
  font-weight: 300;
}
.cm-open-list__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}
.cm-open-list__item:last-child {
  margin-bottom: 0;
}

.cm-price-list {
  font-size: 1rem;
}
.cm-price-list__item {
  margin-bottom: 50px;
}
.cm-price-list__cat {
  font-weight: 700;
  margin-bottom: 24px;
}
.cm-price-list__field {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}
.cm-price-list__name {
  font-weight: 300;
}
.cm-price-list__hr {
  flex-grow: 1;
  margin: 0 22px;
  height: 1px;
  background-color: #dedede;
}
.cm-price-list__value {
  min-width: max-content;
  display: flex;
  align-items: center;
}
.cm-price-list__value a {
  margin-left: 12px;
}
.cm-price-list__price {
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .cm-price-list__field {
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
    padding-bottom: 14px;
  }
  .cm-price-list__hr {
    display: none;
  }
  .cm-price-list__name {
    padding-right: 30px;
  }
}
@media screen and (min-width: 768px) {
  .cm-price-list {
    font-size: 1.125rem;
    letter-spacing: 0.02em;
  }
}
.cm-slider {
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.cm-slider .swiper-wrapper, .cm-slider .swiper-slide {
  display: flex;
  width: 100%;
  height: 100% !important;
}
.cm-slider__item {
  width: 100%;
  position: relative;
  display: flex;
  min-height: 360px;
}
.cm-slider__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.cm-slider__box {
  width: 100%;
  padding: 30px 40px 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  position: relative;
}
.cm-slider__title {
  margin-bottom: 20px;
  color: #c71066;
}
.cm-slider__text {
  margin-bottom: 30px;
}

.cm-slider-nav {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  pointer-events: none;
}
.cm-slider-nav__btn {
  width: 35px;
  height: 60px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
}

@media screen and (min-width: 768px) {
  .cm-slider__item {
    padding: 30px 76px;
    min-height: 460px;
    align-items: center;
  }
  .cm-slider__box {
    max-width: 620px;
    padding: 30px 40px;
  }
  .cm-slider__title {
    margin-bottom: 26px;
  }
  .cm-slider__text {
    margin-bottom: 40px;
  }
}
.cm-offer-slider {
  overflow: hidden;
}
.cm-offer-slider .swiper-wrapper {
  align-items: stretch;
}
.cm-offer-slider .swiper-slide {
  display: flex;
}
.cm-offer-slider__item {
  width: 100%;
  padding: 20px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 260px;
}
.cm-offer-slider__item:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.cm-offer-slider__title {
  margin-bottom: 20px;
  color: #c71066;
}
.cm-offer-slider__text {
  margin-bottom: 20px;
}
.cm-offer-slider__icon {
  margin-top: auto;
}

.cm-offer-slider-scrollbar.swiper-scrollbar {
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
  height: 6px;
  border-radius: 5px;
  background-color: transparent;
  margin-top: 20px;
  z-index: 2;
}
.cm-offer-slider-scrollbar.swiper-scrollbar::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #dedede;
}
.cm-offer-slider-scrollbar.swiper-scrollbar .swiper-scrollbar-drag {
  background-color: #c71066;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (min-width: 576px) {
  .cm-offer-slider__item {
    min-height: 340px;
    padding: 30px;
  }
  .cm-offer-slider__title {
    margin-bottom: 30px;
  }
  .cm-offer-slider__text {
    margin-bottom: 30px;
  }
  .cm-offer-slider-scrollbar.swiper-scrollbar {
    margin-top: 40px;
  }
}
.cm-blog-box {
  display: block;
  width: 100%;
  position: relative;
  height: 268px;
  border-radius: 5px;
  overflow: hidden;
}
.cm-blog-box::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(180deg, rgba(87, 87, 86, 0) 0%, #575756 100%);
}
.cm-blog-box__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.cm-blog-box__name {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  padding: 26px;
  color: #ffffff;
  transition: all 0.3s;
}
.cm-blog-box__cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s;
  pointer-events: none;
}
.cm-blog-box:hover .cm-blog-box__name {
  opacity: 0;
}
.cm-blog-box:hover .cm-blog-box__cover {
  opacity: 1;
}

.cm-result-box__form {
  margin-bottom: 40px;
}

.cm-result-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.cm-result-list__col {
  width: 100%;
  padding: 0 12px;
  margin-bottom: 50px;
}
.cm-result-list__col.hide {
  display: none;
}
.cm-result-list__col ul li.hide {
  display: none;
}
@media screen and (min-width: 768px) {
  .cm-result-list__col {
    width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .cm-result-list__col {
    width: 33.33%;
  }
}
@media screen and (min-width: 1200px) {
  .cm-result-list__col {
    width: 25%;
  }
}
.cm-result-list__cat {
  font-size: 1rem;
  color: #b1b1b1;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.cm-filter-form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.cm-filter-form__item {
  width: 340px;
  padding: 0 8px;
  margin-bottom: 16px;
}
.cm-filter-form__actions {
  display: flex;
}
.cm-filter-form__actions button, .cm-filter-form__actions a {
  margin-left: 16px;
  margin-bottom: 16px;
}

.cm-gallery {
  margin-bottom: 80px;
}
.cm-gallery__content {
  padding: 40px 0;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}
.cm-gallery__title {
  color: #c71066;
  margin-bottom: 36px;
}

.cm-gallery-slider {
  overflow: hidden;
  position: relative;
}
.cm-gallery-slider__item {
  position: relative;
  padding-bottom: 76%;
}
.cm-gallery-slider__item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.cm-breadcrumbs {
  padding: 28px 0;
  background-image: linear-gradient(92deg, #c71066 0%, #ed6ea6 100%);
  font-size: 0.75rem;
  font-weight: 300;
  color: #fc8ebe;
  margin-bottom: 30px;
}
@media screen and (min-width: 576px) {
  .cm-breadcrumbs {
    margin-bottom: 60px;
  }
}
.cm-breadcrumbs__header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  color: #ffffff;
}
@media screen and (min-width: 576px) {
  .cm-breadcrumbs__header {
    margin-bottom: 12px;
  }
}
.cm-breadcrumbs__info {
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 300;
  padding-left: 18px;
}
@media screen and (max-width: 767px) {
  .cm-breadcrumbs__info {
    width: 100%;
    padding-left: 0;
  }
}
@media screen and (min-width: 576px) {
  .cm-breadcrumbs__info {
    font-size: 1.25rem;
    line-height: 1.75em;
  }
}
@media screen and (min-width: 1200px) {
  .cm-breadcrumbs__info {
    font-size: 2rem;
    line-height: 1.375em;
  }
}
.cm-breadcrumbs ol {
  display: flex;
}
@media screen and (max-width: 575px) {
  .cm-breadcrumbs ol {
    display: none;
  }
}
.cm-breadcrumbs ol li {
  margin-right: 2px;
}
.cm-breadcrumbs ol li > a {
  text-decoration: underline;
}
.cm-breadcrumbs ol li::after {
  content: "/";
  display: inline-block;
  padding: 0 4px;
}
.cm-breadcrumbs ol li:last-child > a {
  text-decoration: none;
}
.cm-breadcrumbs ol li:last-child::after {
  content: none;
}

.cm-post-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
}
.cm-post-list__item {
  display: flex;
  width: 100%;
  padding: 0 14px;
  margin-bottom: 28px;
}
@media screen and (min-width: 768px) {
  .cm-post-list__item {
    width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .cm-post-list__item {
    width: 33.33%;
  }
}
@media screen and (min-width: 1360px) {
  .cm-post-list__item {
    width: 25%;
  }
}
.cm-post-list--3 .cm-post-list__item {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .cm-post-list--3 .cm-post-list__item {
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .cm-post-list--3 .cm-post-list__item {
    width: 33.33%;
  }
}
.cm-post-list--2 .cm-post-list__item {
  width: 100%;
}
@media screen and (min-width: 992px) {
  .cm-post-list--2 .cm-post-list__item {
    width: 50%;
  }
}

.cm-post-box {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  background-color: transparent;
  position: relative;
  overflow: hidden;
}
.cm-post-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #dedede;
  border-radius: 5px;
  transition: all 0.3s;
  pointer-events: none;
}
.cm-post-box__image {
  position: relative;
  padding-bottom: 100%;
}
.cm-post-box__image--small {
  padding-bottom: 50%;
}
.cm-post-box__image--medium {
  padding-bottom: 65%;
}
.cm-post-box__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.cm-post-box__cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(87, 87, 86, 0.5);
  z-index: 3;
  opacity: 0;
  transition: all 0.3s;
}
.cm-post-box__icon {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50px;
  bottom: calc(0% - 30px);
  background-color: #ffffff;
  z-index: 4;
}
.cm-post-box__desc {
  padding: 26px 30px;
  background-color: #ffffff;
}
@media screen and (min-width: 1560px) {
  .cm-post-box__desc--big {
    padding: 50px;
  }
}
.cm-post-box__title {
  font-size: 1.25rem;
  line-height: 1.5em;
  color: #c71066;
  margin-bottom: 12px;
  font-weight: 600;
}
.cm-post-box__tags {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5em;
}
.cm-post-box__category {
  text-transform: uppercase;
  margin-bottom: 20px;
  line-height: 1.375em;
  font-size: 0.875rem;
}
.cm-post-box:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.cm-post-box:hover::after {
  opacity: 0;
}
.cm-post-box:hover .cm-post-box__cover {
  opacity: 1;
}

.cm-post-related {
  padding: 30px 0;
}

.post-list__item {
  margin-bottom: 10px;
}

.cm-post-box-s {
  display: flex;
  align-items: flex-start;
}
.cm-post-box-s__image {
  width: 120px;
  min-width: 120px;
  height: 80px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.cm-post-box-s__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.cm-post-box-s__desc {
  flex-grow: 1;
  padding-left: 16px;
}
.cm-post-box-s__tags {
  font-size: 0.875rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 6px;
}
.cm-post-box-s__title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5em;
}
.cm-post-box-s:hover {
  color: #c71066;
}

@media screen and (min-width: 576px) {
  .cm-post-box-s__image {
    width: 160px;
    min-width: 160px;
    height: 120px;
  }
  .cm-post-box-s__desc {
    padding-left: 20px;
  }
  .cm-post-box-s__tags {
    margin-bottom: 20px;
  }
}
.cm-map-search {
  background-color: #f6f6f6;
  padding: 50px 0;
  margin-top: -60px;
}
.cm-map-search__header {
  margin-bottom: 30px;
}

.cm-place-map {
  position: relative;
  width: 100%;
  height: 520px;
  margin-bottom: 60px;
}

.cm-search {
  padding: 40px 0 20px;
  background-color: #f6f6f6;
  margin-bottom: 40px;
  margin-top: -30px;
}
@media screen and (min-width: 576px) {
  .cm-search {
    padding: 50px 0;
    margin-top: -60px;
    margin-bottom: 60px;
  }
}

.cm-footer {
  overflow: hidden;
  background-color: #f6f6f6;
}
.cm-footer__contact {
  border-bottom: 1px solid #b4b4b4;
  padding: 50px 0;
}
.cm-footer__row {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cm-footer__row .btn-contact {
  margin-bottom: 30px;
}
.cm-footer__row .btn {
  margin-bottom: 10px;
}
.cm-footer__info {
  padding-top: 70px;
}
.cm-footer__socials {
  text-align: right;
}
.cm-footer__copyright {
  color: #878787;
  font-size: 1rem;
  padding: 50px 0;
}
.cm-footer__copyright strong {
  font-weight: 600;
}
.cm-footer__copyright a:hover {
  text-decoration: underline;
  color: #ed6ea6;
}

@media screen and (min-width: 576px) {
  .cm-footer-nav {
    column-count: 2;
    column-gap: 40px;
  }
}
@media screen and (min-width: 992px) {
  .cm-footer-nav {
    column-count: 3;
  }
}
.cm-footer-nav__item {
  break-inside: avoid;
  margin-bottom: 60px;
}
.cm-footer-nav__title {
  font-size: 1rem;
  letter-spacing: 0.01em;
  font-weight: 700;
  margin-bottom: 20px;
}

@media screen and (min-width: 576px) {
  .cm-footer__copyright {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
@media screen and (min-width: 992px) {
  .cm-footer__row {
    flex-direction: row;
    align-items: center;
  }
  .cm-footer__row .btn-contact {
    margin-right: auto;
    margin-bottom: 0;
  }
  .cm-footer__row .btn-contact:first-child {
    margin-right: 60px;
  }
  .cm-footer__row .btn {
    margin-left: 20px;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .cm-footer__row .btn-contact:first-child {
    margin-right: 160px;
  }
}
@media screen and (min-width: 1360px) {
  .cm-footer__info {
    display: flex;
  }
  .cm-footer__nav {
    flex-grow: 1;
  }
}
.cm-article-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.cm-article-list__item {
  width: 100%;
  padding: 0 15px;
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .cm-article-list__item {
    width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .cm-article-list__item {
    width: 33.33%;
  }
}
.cm-article-list__icon {
  height: 82px;
  margin-bottom: 32px;
}
.cm-article-list__icon img {
  max-width: 100%;
  max-height: 80px;
  width: auto;
  height: auto;
}
.cm-article-list__title {
  color: #c71066;
  margin-bottom: 14px;
}

.cm-benefits {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 -8px;
}
.cm-benefits__item {
  width: 50%;
  padding: 0 8px;
  margin-bottom: 20px;
}
@media screen and (min-width: 576px) {
  .cm-benefits__item {
    width: 220px;
  }
}
.cm-benefits__image {
  width: 100%;
  height: 172px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #f6f6f6;
}
.cm-benefits__image img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.cm-benefits__name {
  color: #575756;
  text-align: center;
  padding: 20px 0;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 300;
}

.cm-tabs__item {
  margin-bottom: 8px;
}
.cm-tabs__header {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  background-color: #f6f6f6;
  transition: all 0.3s;
  cursor: pointer;
}
.cm-tabs__toggle {
  width: 14px;
  height: 14px;
  position: relative;
  margin-right: 10px;
}
.cm-tabs__toggle::before, .cm-tabs__toggle::after {
  content: "";
  width: 14px;
  height: 2px;
  position: absolute;
  top: 5px;
  left: 0;
  background-color: #c71066;
  transition: all 0.3s;
}
.cm-tabs__toggle::after {
  transform: rotate(90deg);
}
.cm-tabs__title {
  font-size: 1rem;
  letter-spacing: 0.02em;
  font-weight: 300;
  line-height: 1.5em;
}
@media screen and (min-width: 576px) {
  .cm-tabs__title {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 992px) {
  .cm-tabs__title {
    font-size: 1.25rem;
  }
}
.cm-tabs__content {
  display: none;
  padding: 20px 0;
}
.cm-tabs__item--active .cm-tabs__header {
  background-image: linear-gradient(94deg, #c71066 0%, #ed6ea6 100%);
  color: #ffffff;
}
.cm-tabs__item--active .cm-tabs__toggle::before, .cm-tabs__item--active .cm-tabs__toggle::after {
  background-color: #ffffff;
}
.cm-tabs__item--active .cm-tabs__toggle::after {
  transform: rotate(0);
}
.cm-tabs__item--active .cm-tabs__content {
  display: block;
}

@media screen and (min-width: 576px) {
  .cm-tabs__header {
    padding: 20px 40px;
  }
  .cm-tabs__toggle {
    margin-right: 30px;
  }
}
.cm-step-list__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  margin-bottom: 28px;
}
.cm-step-list__icon img {
  max-width: 100%;
  max-height: 72px;
  width: auto;
  height: auto;
}
.cm-step-list__title {
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 575px) {
  .cm-step-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cm-step-list__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cm-step-list__item::after {
    content: "";
    width: 1px;
    height: 30px;
    border-right: 1px dashed #b1b1b1;
    margin: 20px 0;
  }
  .cm-step-list__item:last-child::after {
    content: none;
  }
}
@media screen and (min-width: 576px) {
  .cm-step-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  .cm-step-list__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 33.33%;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 576px) and (min-width: 992px) {
  .cm-step-list__item {
    width: 20%;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 576px) {
  .cm-step-list__item::after {
    content: "";
    width: 80px;
    padding-bottom: 36px;
    border-bottom: 1px dashed #b1b1b1;
  }
}
@media screen and (min-width: 576px) {
  .cm-step-list__item:last-child::after {
    content: none;
  }
}
@media screen and (min-width: 576px) {
  .cm-step-list__content {
    max-width: 182px;
  }
}
.cm-product-category-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}
.cm-product-category-list__item {
  display: flex;
  width: 100%;
  padding: 0 6px;
  margin-bottom: 6px;
}
@media screen and (min-width: 576px) {
  .cm-product-category-list__item {
    width: 50%;
    margin-bottom: 12px;
  }
}
@media screen and (min-width: 1200px) {
  .cm-product-category-list__item {
    width: 25%;
  }
}

.cm-cat-box {
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: relative;
}
.cm-cat-box__title {
  font-size: 1rem;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  font-weight: 600;
  margin-bottom: 4px;
}
.cm-cat-box__icon {
  margin-right: 18px;
}
.cm-cat-box__arrow {
  position: absolute;
  right: 20px;
  bottom: 10px;
  opacity: 0;
}
.cm-cat-box__arrow svg {
  fill: #c71066;
}
.cm-cat-box__info {
  font-size: 0.875rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 700;
}
.cm-cat-box:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.cm-cat-box:hover .cm-cat-box__arrow {
  opacity: 1;
}
.cm-cat-box:hover .cm-cat-box__title {
  color: #c71066;
}
.cm-cat-box--green {
  background-color: #97bf26;
}
.cm-cat-box--green::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 5px;
  border: 1px solid #b1e327;
}
.cm-cat-box--green .cm-cat-box__arrow svg {
  fill: #ffffff;
}
.cm-cat-box--green .cm-cat-box__title {
  color: #ffffff;
}
.cm-cat-box--green:hover .cm-cat-box__title {
  color: #ffffff;
}
.cm-cat-box--all {
  background-image: linear-gradient(110deg, #c71066 0%, #ed6ea6 100%);
  color: #ffffff;
}
@media screen and (max-width: 575px) {
  .cm-cat-box--all {
    justify-content: center;
  }
}
.cm-cat-box--all .cm-cat-box__arrow svg {
  fill: #ffffff;
}

@media screen and (min-width: 768px) {
  .cm-cat-box {
    padding: 30px;
  }
  .cm-cat-box__title {
    font-size: 1.25rem;
    max-width: 190px;
  }
  .cm-cat-box__icon {
    margin-right: 28px;
  }
  .cm-cat-box__arrow {
    right: 40px;
    bottom: 30px;
  }
  .cm-cat-box__info {
    max-width: 180px;
  }
}
.cm-box-comment {
  border-radius: 5px;
  border: 1px solid #c71066;
  padding: 22px 20px 22px;
}
.cm-box-comment__title {
  color: #c71066;
  margin-bottom: 24px;
}
.cm-box-comment__image {
  margin-bottom: 30px;
}

@media screen and (min-width: 992px) {
  .cm-box-comment {
    padding: 22px 40px 32px;
  }
  .cm-box-comment__row {
    display: flex;
  }
  .cm-box-comment__image {
    width: 150px;
    min-width: 150px;
    margin-right: 50px;
    margin-bottom: 0;
  }
  .cm-box-comment__desc {
    flex-grow: 1;
  }
}
.cm-newsletter {
  padding: 30px 20px;
  max-width: 1440px;
  margin: 0 auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 1px solid #dedede;
  background-color: #ffffff;
}
.cm-newsletter__desc {
  margin-bottom: 30px;
}
.cm-newsletter__icon {
  max-width: 50px;
  margin-bottom: 16px;
}
.cm-newsletter__info {
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 300;
}
.cm-newsletter__header {
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 700;
  margin-bottom: 8px;
}
.cm-newsletter.box input[type=submit] {
  width: 100%;
}

.newsletter-form__fields input[type=submit] {
  margin-top: 6px;
}

@media screen and (min-width: 576px) {
  .cm-newsletter {
    padding: 40px 40px;
  }
  .cm-newsletter__desc {
    margin-bottom: 30px;
  }
  .cm-newsletter__icon {
    max-width: 60px;
  }
  .cm-newsletter.full .cm-newsletter__desc {
    display: flex;
    align-items: center;
  }
  .cm-newsletter.full .cm-newsletter__icon {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .cm-newsletter.full .newsletter-form__fields {
    position: relative;
  }
  .cm-newsletter.full .newsletter-form__fields input[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) {
  .cm-newsletter.full {
    padding: 60px 80px;
  }
  .cm-newsletter.full .cm-newsletter__icon {
    max-width: 80px;
    margin-right: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .cm-newsletter.full .cm-newsletter__row {
    display: flex;
  }
  .cm-newsletter.full .cm-newsletter__desc {
    width: 50%;
    margin-bottom: 0;
  }
  .cm-newsletter.full .cm-newsletter__form {
    width: 50%;
    padding-left: 40px;
  }
}
@media screen and (min-width: 1380px) {
  .cm-newsletter.full .cm-newsletter__icon {
    max-width: 100px;
    margin-right: 40px;
  }
  .cm-newsletter.full .cm-newsletter__info {
    font-size: 1.125rem;
  }
  .cm-newsletter.full .cm-newsletter__header {
    font-size: 1.25rem;
  }
  .cm-newsletter.full .cm-newsletter__form {
    padding-left: 80px;
  }
}
.cm-partners__header {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #dedede;
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-bottom: 30px;
}
.cm-partners__header p {
  width: max-content;
  position: relative;
  margin: 0 auto;
}
.cm-partners__header p::before, .cm-partners__header p::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 50vw;
  height: 1px;
  background-color: #dedede;
}
.cm-partners__header p::before {
  right: calc(100% + 40px);
}
.cm-partners__header p::after {
  left: calc(100% + 40px);
}

.cm-partners-slider {
  overflow: hidden;
}
.cm-partners-slider__item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
}
.cm-partners-slider__item img {
  max-height: 80px;
  max-width: 220px;
  width: auto;
  height: auto;
  filter: grayscale(100%);
  opacity: 0.5;
}
.cm-partners-slider__item:hover img {
  filter: grayscale(0);
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .cm-partners__header {
    margin-bottom: 60px;
  }
}
.cm-post {
  margin-bottom: 30px;
}
.cm-post__image {
  position: relative;
  padding-bottom: 37.5%;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}
.cm-post__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.cm-post__info {
  margin-bottom: 30px;
}
.cm-post__tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #575756;
  margin-bottom: 12px;
}
.cm-post__date {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.cm-post__date img {
  margin-right: 10px;
}
.cm-post__date::after {
  content: "/";
  padding: 0 10px;
}
.cm-post__cat {
  margin-bottom: 4px;
}
.cm-post__places {
  font-size: 0.875rem;
  font-weight: 300;
}
.cm-post__places span {
  font-weight: 500;
  padding-right: 4px;
}
.cm-post__places a {
  color: #ed6ea6;
}
.cm-post__places a:hover {
  color: #c71066;
  text-decoration: underline;
}
.cm-post__places a:after {
  content: ", ";
}
.cm-post__places a:last-child::after {
  content: none;
}

@media screen and (min-width: 768px) {
  .cm-post {
    margin-bottom: 60px;
  }
  .cm-post__info {
    margin-bottom: 50px;
  }
}
.cm-contact-list__item {
  margin-bottom: 32px;
}

.cm-contact-box {
  width: 100%;
  padding: 40px 30px 10px;
  border-radius: 5px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cm-contact-box__header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.cm-contact-box__img {
  max-width: 50px;
  max-height: 50px;
  width: auto;
  height: auto;
  margin-right: 20px;
}
.cm-contact-box__info, .cm-contact-box__desc {
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 30px;
}
.cm-contact-box__info p, .cm-contact-box__desc p {
  margin-bottom: 12px;
}
.cm-contact-box__info a, .cm-contact-box__desc a {
  font-size: 1rem;
}
.cm-contact-box__info b, .cm-contact-box__info strong, .cm-contact-box__desc b, .cm-contact-box__desc strong {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}
.cm-contact-box__info a {
  font-size: 1.125rem;
}
.cm-contact-box__footer {
  margin-top: auto;
  margin-bottom: 30px;
}

@media screen and (min-width: 576px) {
  .cm-contact-box__img {
    max-width: 60px;
    max-height: 60px;
    margin-right: 20px;
  }
  .cm-contact-box__info a {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .cm-contact-list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 -8px;
  }
  .cm-contact-list__item {
    display: flex;
    width: 33.33%;
    padding: 0 8px;
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 1440px) {
  .cm-contact-list {
    margin: 0 -16px;
  }
  .cm-contact-list__item {
    padding: 0 16px;
  }
  .cm-contact-box {
    padding: 40px 60px 10px;
  }
  .cm-contact-box__img {
    max-width: 80px;
    max-height: 80px;
    margin-right: 30px;
  }
}
.cm-about__header {
  color: #c71066;
  margin-bottom: 30px;
}

.cm-about-timeline {
  padding: 60px 0;
  background-color: #f6f6f6;
}

@media screen and (max-width: 1199px) {
  .cm-about__desc {
    margin-bottom: 40px;
  }
  .cm-about__image img {
    margin: 0 auto;
  }
}
@media screen and (min-width: 1200px) {
  .cm-about__row {
    display: flex;
    margin: 0 -40px;
  }
  .cm-about__desc {
    width: 60%;
    padding: 0 40px;
  }
  .cm-about__image {
    width: 40%;
    padding: 0 40px;
  }
}
.cm-timeline-slider {
  position: relative;
}
.cm-timeline-slider .swiper-slide {
  width: 400px;
}
.cm-timeline-slider .swiper-slide:first-child .cm-timeline-slider__point::before {
  content: none;
}
.cm-timeline-slider__image {
  width: 100%;
  height: 200px;
  position: relative;
  margin-bottom: 24px;
}
.cm-timeline-slider__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cm-timeline-slider__date {
  color: #c71066;
  margin-bottom: 18px;
}
.cm-timeline-slider__point {
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #ed6ea6;
  margin-left: 50px;
  position: relative;
  margin-bottom: 20px;
}
.cm-timeline-slider__point::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  height: 1px;
  width: 100px;
  background-color: #ed6ea6;
}
.cm-timeline-slider__point::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  height: 1px;
  width: 400px;
  background-color: #ed6ea6;
}

.cm-timeline-slider-scrollbar.swiper-scrollbar {
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
  height: 6px;
  border-radius: 5px;
  background-color: transparent;
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .cm-timeline-slider-scrollbar.swiper-scrollbar {
    margin-top: 100px;
  }
}
.cm-timeline-slider-scrollbar.swiper-scrollbar::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #dedede;
}
.cm-timeline-slider-scrollbar.swiper-scrollbar .swiper-scrollbar-drag {
  background-color: #c71066;
  border-radius: 5px;
  cursor: pointer;
}

.cm-awards-slider {
  position: relative;
  overflow: hidden;
  padding: 0 18px;
}
.cm-awards-slider .swiper-wrapper {
  align-items: stretch;
}
.cm-awards-slider .swiper-slide {
  display: flex;
}
.cm-awards-slider__item {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #dedede;
}
@media screen and (min-width: 1400px) {
  .cm-awards-slider__item {
    padding: 40px;
  }
}
.cm-awards-slider__icon {
  height: 200px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cm-awards-slider__text {
  text-align: center;
}

.cm-adv-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
}
.cm-adv-list__item {
  width: 100%;
  padding: 0 30px;
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .cm-adv-list__item {
    width: 50%;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .cm-adv-list__item {
    width: 33.33%;
    margin-bottom: 60px;
  }
}
.cm-adv-list__box {
  position: relative;
  padding-left: 45px;
}
.cm-adv-list__box::before {
  content: "";
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background-color: #97bf26;
  position: absolute;
  top: 5px;
  left: 0;
  background-image: url(../../assets/checked-white.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.cm-box-form {
  width: 100%;
  padding: 30px 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  border: 1px solid #b1b1b1;
  background-color: #ffffff;
}
.cm-box-form__img {
  margin-bottom: 30px;
}
.cm-box-form__title {
  margin-bottom: 20px;
}
.cm-box-form__info {
  margin-bottom: 30px;
}
.cm-box-form__contact {
  border-top: 1px solid #ed6ea6;
  padding-top: 30px;
  margin-top: 30px;
  font-size: 1rem;
}
.cm-box-form__contact p {
  margin-bottom: 20px;
}
.cm-box-form__contact b, .cm-box-form__contact strong {
  font-weight: 700;
  letter-spacing: 0.01em;
  color: #c71066;
}
.cm-box-form__contact a[href^="tel:"], .cm-box-form__contact a[href^="mailto:"], .cm-box-form__contact a[href^="https://www.google.com/maps/"] {
  display: flex;
  align-items: center;
  color: #575756;
}
.cm-box-form__contact a[href^="tel:"]::before, .cm-box-form__contact a[href^="mailto:"]::before, .cm-box-form__contact a[href^="https://www.google.com/maps/"]::before {
  content: "";
  width: 22px;
  height: 22px;
  margin-right: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.cm-box-form__contact a[href^="tel:"]:hover, .cm-box-form__contact a[href^="mailto:"]:hover, .cm-box-form__contact a[href^="https://www.google.com/maps/"]:hover {
  color: #ed6ea6;
  text-decoration: underline;
}
.cm-box-form__contact a[href^="tel:"]::before {
  background-image: url(../../assets/link-tel.svg);
}
.cm-box-form__contact a[href^="mailto:"]::before {
  background-image: url(../../assets/link-mailto.svg);
}
.cm-box-form__contact a[href^="https://www.google.com/maps/"]::before {
  background-image: url(../../assets/link-place.svg);
}

@media screen and (min-width: 768px) {
  .cm-box-form {
    padding: 50px 60px;
  }
}
.cm-box-contact {
  max-width: 1080px;
  margin: 0 auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  padding: 40px 60px;
  display: flex;
  align-items: center;
}
.cm-box-contact__icon {
  margin-right: 60px;
}
.cm-box-contact__title {
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  .cm-box-contact {
    padding: 30px 20px;
    flex-direction: column;
  }
  .cm-box-contact__icon {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .cm-box-contact__title {
    margin-left: auto;
    margin-bottom: 20px;
  }
}
.cm-file-list__item {
  margin-bottom: 20px;
}

.file-link {
  display: flex;
  align-items: flex-start;
  font-size: 1rem;
  font-weight: 300;
  color: #575756;
  letter-spacing: 0.01em;
  line-height: 1.5em;
}
.file-link svg {
  margin-right: 16px;
}
.file-link svg path {
  fill: #ed6ea6;
}
.file-link:hover {
  color: #ed6ea6;
  text-decoration: underline;
}

.cm-place-list__item {
  margin-bottom: 20px;
}
.cm-place-list__item a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.cm-place-list__item a img {
  margin-right: 12px;
}
.cm-place-list__item a strong {
  text-transform: uppercase;
  margin-right: 6px;
}
.cm-place-list__item a:hover {
  color: #ed6ea6;
}
.cm-place-list__item a:hover strong {
  color: #c71066;
}

.cm-review-slider {
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
}
.cm-review-slider .swiper-slide {
  opacity: 0.4;
}
.cm-review-slider .swiper-slide-active {
  opacity: 1;
  border: 1px solid #DEDEDE;
}
.cm-review-slider__box {
  width: 100%;
  padding: 30px 20px;
  text-align: center;
}
.cm-review-slider__title {
  font-size: 1.5rem;
  font-style: italic;
  color: #c71066;
  margin-bottom: 40px;
}
.cm-review-slider__text {
  margin-bottom: 50px;
}

@media screen and (min-width: 992px) {
  .cm-review-slider {
    max-width: 920px;
    padding: 0 60px;
  }
  .cm-review-slider__box {
    padding: 50px 40px;
  }
}
.cm-box-link {
  padding: 26px 40px;
  display: flex;
  align-items: center;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
}
.cm-box-link__icon {
  width: 44px;
  min-width: 44px;
  margin-right: 30px;
}
.cm-box-link__title {
  margin-right: 30px;
}
.cm-box-link a {
  margin-left: auto;
  min-width: 140px;
}

@media screen and (max-width: 575px) {
  .cm-box-link {
    padding: 20px 16px;
    flex-wrap: wrap;
  }
  .cm-box-link__icon {
    width: 32px;
    min-width: 32px;
    margin-right: 16px;
  }
  .cm-box-link__title {
    margin-right: 0;
  }
  .cm-box-link a {
    min-width: 100%;
    margin-top: 16px;
  }
}
.cm-check-list__item {
  display: flex;
  margin-bottom: 20px;
}
.cm-check-list__item::before {
  content: "";
  width: 18px;
  min-width: 18px;
  height: 18px;
  background-color: #97BF26;
  border-radius: 2px;
  margin-right: 20px;
  background-image: url(../../assets/checked-white.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
}

.cm-post-tabs {
  padding-top: 20px;
}
.cm-post-tabs__item {
  margin-bottom: 10px;
}
.cm-post-tabs .cm-box-link {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .cm-post-tabs {
    display: flex;
    justify-content: center;
    margin: 0 -20px;
  }
  .cm-post-tabs__item {
    display: flex;
    width: 50%;
    max-width: 620px;
    padding: 0 20px;
    margin-bottom: 0;
  }
}
.cm-ig-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 30px -5px;
}
.cm-ig-list__item {
  width: 50%;
  padding: 0 5px;
  margin-bottom: 10px;
}

.cm-ig-post-box {
  display: block;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.cm-ig-post-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: #c71066;
  opacity: 0;
  transition: all 0.3s;
}
.cm-ig-post-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  transition: all 0.3s;
}
.cm-ig-post-box:hover::after {
  opacity: 0.2;
}

.cm-doctor-menu {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
}
.cm-doctor-menu__item {
  padding: 20px 20px 0;
}
.cm-doctor-menu__item a:hover {
  color: #ed6ea6;
}

.header {
  width: 100%;
  position: absolute;
  z-index: 11;
  display: flex;
  align-items: center;
  height: 134px;
  border-bottom: 1px solid #dedede;
  transition: all 0.25s ease-in-out;
  background-color: #ffffff;
}
.header__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__contact {
  display: flex;
  align-items: center;
}
.header__contact a {
  margin-left: 20px;
}
@media screen and (min-width: 1360px) {
  .header__contact a {
    margin-left: 54px;
  }
}

.hamburger {
  width: 40px;
  height: 40px;
  z-index: 100;
  position: relative;
  cursor: pointer;
  margin-left: auto;
  overflow: hidden;
  display: inline-flex;
}
.hamburger svg {
  position: absolute;
  right: -15%;
  height: 100%;
  transform-origin: top right;
}
.hamburger path {
  fill: none;
  stroke: #c71066;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  --length: 24;
  --offset: -38;
  stroke-dasharray: var(--length) var(--total-length);
  stroke-dashoffset: var(--offset);
}
.hamburger path:nth-child(1), .hamburger path:nth-child(3) {
  --total-length: 126.64183044433594;
}
.hamburger path:nth-child(2) {
  --total-length: 70;
}

.nav-toggled .nav {
  transform: translateX(0);
  opacity: 1;
}
.nav-toggled .hamburger path {
  stroke: #c71066;
}
.nav-toggled .hamburger path:nth-child(1), .nav-toggled .hamburger path:nth-child(3) {
  --length: 22.627416998;
  --offset: -94.1149185097;
}
.nav-toggled .hamburger path:nth-child(2) {
  --length: 0;
  --offset: -50;
}

@media screen and (max-width: 1199px) {
  .header {
    height: 80px;
  }
  .header__branding {
    max-width: 120px;
    margin-right: auto;
  }
  .header__contact {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .hamburger {
    display: none;
  }
}
.header-nav {
  height: 82px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  overflow: visible;
  position: relative;
}
.header-nav__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-nav__action {
  display: flex;
  align-items: center;
}
.header-nav__action a, .header-nav__action button {
  margin-left: 10px;
}
.header-nav__search {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 8;
  display: none;
}
.header-nav__search.active {
  display: block;
}

@media screen and (max-width: 1199px) {
  .header-nav__row {
    justify-content: flex-end;
  }
  .nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    background: #fff;
    transform: translateY(-150%);
    box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.2);
  }
}
.menu {
  display: flex;
  align-items: center;
  margin: 0 -20px;
}
.menu__item {
  position: relative;
}
.menu__item > a {
  padding: 30px 10px;
  font-size: 1em;
  font-weight: 300;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1440px) {
  .menu__item > a {
    padding: 30px 20px;
  }
}
.menu__item > a svg {
  margin-left: 10px;
}
.menu__item > a svg path {
  fill: #575756;
}
.menu__item.current-menu-item > a {
  font-weight: 700;
  color: #c71066;
}
.menu__item:hover > a {
  color: #c71066;
}
.menu__item:hover > a svg path {
  fill: #c71066;
}
.menu__item:hover > .submenu {
  display: block;
}

.submenu {
  display: none;
  background-color: #ffffff;
  z-index: 9;
}

@media screen and (max-width: 1199px) {
  .menu {
    flex-direction: column;
  }
  .menu__item {
    width: 100%;
    border-bottom: 1px solid #dedede;
  }
  .menu__item.menu-item-has-children > a {
    pointer-events: none;
  }
  .menu__item > a {
    width: 100%;
    padding: 20px;
    justify-content: center;
  }
  .submenu {
    position: relative;
    background-color: #f6f6f6;
  }
  .submenu__title {
    display: none;
  }
  .submenu__items {
    margin-bottom: 0 !important;
  }
  .submenu__item {
    padding-left: 0 !important;
  }
  .submenu__item a {
    display: block;
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #dedede;
  }
}
@media screen and (min-width: 1200px) {
  .submenu {
    position: absolute;
    top: 100%;
    left: -40px;
    box-shadow: 0 30px 30px rgba(0, 0, 0, 0.2);
    border: 1px solid #dedede;
    padding: 40px 60px 20px;
  }
  .submenu__row {
    display: flex;
    margin: 0 -15px;
  }
  .submenu__col {
    width: 250px;
    padding: 0 15px;
  }
  .submenu__title {
    font-size: 1rem;
    line-height: 1.5em;
    letter-spacing: 0.01em;
    font-weight: 700;
    color: #878787;
    margin-bottom: 20px;
  }
}
.search-box {
  background-color: #ffffff;
  border: 1px solid #DEDEDE;
  padding: 80px 0;
  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.2);
}
.search-box__form {
  max-width: 1000px;
  margin: 0 auto;
}

.blog-nav {
  background-image: linear-gradient(92deg, #c71066 0%, #ed6ea6 100%);
  margin-top: -40px;
  margin-bottom: 50px;
  padding-bottom: 0;
}
@media screen and (min-width: 576px) {
  .blog-nav {
    margin-top: -60px;
    margin-bottom: 80px;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .blog-nav {
    margin-top: -80px;
    margin-bottom: 80px;
    padding-bottom: 0;
  }
}
.blog-nav .menu__item > a {
  color: #ffffff;
  font-weight: 500;
}
.blog-nav .menu__item > a svg path {
  fill: #ffffff;
}

@media screen and (max-width: 1199px) {
  .blog-nav .submenu__item::before {
    content: none;
  }
}
.cmh-hero {
  margin-bottom: 30px;
  position: relative;
}
.cmh-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  height: calc(50% - 30px);
}
.cmh-hero__box {
  position: relative;
}
.cmh-hero__slider {
  display: flex;
  overflow: hidden;
}

@media screen and (min-width: 1200px) {
  .cmh-hero__row {
    display: flex;
    align-items: stretch;
    position: relative;
  }
  .cmh-hero__box {
    display: flex;
    width: 480px;
    min-width: 480px;
  }
  .cmh-hero__slider {
    width: calc(100% - 480px);
    order: 2;
  }
}
.cmh-poz {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
}
.cmh-poz__desc {
  padding: 30px 20px;
}
.cmh-poz__image {
  display: none;
}

@media screen and (min-width: 768px) {
  .cmh-poz__desc {
    padding: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .cmh-poz {
    display: flex;
    align-items: stretch;
  }
  .cmh-poz__desc, .cmh-poz__image {
    width: 50%;
  }
  .cmh-poz__image {
    display: block;
    position: relative;
  }
  .cmh-poz__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
.cmh-about__image {
  display: none;
}

@media screen and (min-width: 1200px) {
  .cmh-about {
    display: flex;
    justify-content: space-between;
  }
  .cmh-about__desc, .cmh-about__image {
    width: 50%;
  }
  .cmh-about__desc {
    max-width: 700px;
    padding-right: 60px;
  }
  .cmh-about__image {
    display: block;
    border-radius: 5px;
    overflow: hidden;
  }
}
.cmh-blog {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.cmh-blog__item {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 20px;
}
@media screen and (min-width: 576px) {
  .cmh-blog__item {
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .cmh-blog__item {
    width: 25%;
  }
}

.cmh-tabs__header {
  max-width: 960px;
  display: flex;
  border: 1px solid #dedede;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  background-color: #ffffff;
}
.cmh-tabs__btn {
  width: 33.33%;
  font-size: 1rem;
  letter-spacing: 0.02em;
  padding: 22px 10px;
  text-align: center;
  border-right: 1px solid #dedede;
}
.cmh-tabs__btn.active {
  font-weight: 600;
  color: #c71066;
}
.cmh-tabs__btn:last-child {
  border-right: none;
}
.cmh-tabs__content {
  background-color: #ffffff;
  border: 1px solid #dedede;
  border-radius: 0 5px 5px 5px;
  padding: 30px 20px;
  position: relative;
}
.cmh-tabs__content::before {
  content: "";
  position: absolute;
  top: 0;
  right: calc(100% + 1px);
  background-color: #ffffff;
  width: 50vw;
  height: 100%;
}
.cmh-tabs__content::after {
  content: "";
  position: absolute;
  top: 0;
  left: calc(100% + 1px);
  background-color: #ffffff;
  width: 50vw;
  height: 100%;
}
.cmh-tabs__item {
  display: none;
}
.cmh-tabs__item.active {
  display: block;
}

@media screen and (min-width: 576px) {
  .cmh-tabs__content {
    padding: 50px;
  }
}
@media screen and (min-width: 768px) {
  .cmh-tabs__btn {
    font-size: 1.25rem;
    padding: 32px;
  }
}
.page-template-template-homepage {
  background-color: #f6f6f6;
}

.cms-about__desc {
  margin-bottom: 20px;
}
.cms-about__info {
  margin-bottom: 60px;
}
.cms-about__header {
  margin-bottom: 40px;
}
.cms-about__title {
  color: #c71066;
  margin-bottom: 4px;
}
.cms-about__tags {
  font-size: 1rem;
  font-weight: 700;
}
.cms-about__text {
  margin-bottom: 60px;
}
.cms-about__image {
  margin-bottom: 26px;
}
.cms-about__image img {
  width: 100%;
}
.cms-about__form {
  max-width: 720px;
}
.cms-about__info-form {
  margin: 40px 0;
}

.cms-about-work {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.cms-about-work__logo {
  margin-bottom: 40px;
}
.cms-about-work__text {
  max-width: 860px;
  margin-bottom: 40px;
}
.cms-about-work__header {
  margin-bottom: 60px;
}

@media screen and (min-width: 1200px) {
  .cms-about__row {
    display: flex;
    align-items: flex-start;
  }
  .cms-about__desc {
    width: 70%;
    padding-right: 40px;
    margin-bottom: 80px;
  }
  .cms-about__info {
    width: 30%;
    margin-bottom: 80px;
  }
  .cms-about--reverse .cms-about__desc {
    order: 2;
    padding-right: 0;
    padding-left: 40px;
  }
}
@media screen and (min-width: 1440px) {
  .cms-about__desc {
    padding-right: 80px;
  }
  .cms-about--reverse .cms-about__desc {
    padding-left: 80px;
  }
}
.cmsd-list {
  margin-bottom: 80px;
}
.cmsd-list__item {
  border-radius: 5px;
  border: 1px solid #dedede;
  margin-bottom: 14px;
  padding: 30px 40px;
}
.cmsd-list__title {
  color: #c71066;
  margin-bottom: 26px;
}

.cmsp-about__image {
  margin-bottom: 30px;
}
.cmsp-about__text {
  margin-bottom: 40px;
}
.cmsp-about__map {
  display: block;
  margin-bottom: 40px;
}

.cmsp-service {
  padding-top: 40px;
  border-top: 1px solid #dedede;
}
.cmsp-service__header {
  color: #c71066;
  margin-bottom: 50px;
}

.cmsp-steps {
  counter-reset: item;
}
.cmsp-steps__item {
  counter-increment: item;
  display: flex;
  margin-bottom: 40px;
}
.cmsp-steps__item::before {
  content: counter(item);
  width: 32px;
  min-width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ed6ea6;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  margin-right: 22px;
  border-radius: 50%;
}
.cmsp-steps__content {
  padding-top: 4px;
}
.cmsp-steps__title {
  font-size: 1.25rem;
  letter-spacing: 0.02em;
  font-weight: 600;
  color: #c71066;
  margin-bottom: 12px;
}

.cm-work-list__item {
  font-size: 0.875rem;
  padding: 8px 0;
}
.cm-work-list__item:nth-child(2n) {
  background-color: #f6f6f6;
}
.cm-work-list__item--header {
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 1.5em;
  background-color: #c71066;
  color: #ffffff;
  padding: 0;
}
.cm-work-list__row {
  display: flex;
  align-items: center;
}
.cm-work-list__col {
  min-width: 160px;
  padding: 14px;
  text-align: center;
}
.cm-work-list a {
  transition: all 0.3s;
}
.cm-work-list a:hover {
  color: #c71066;
}

table.cm-table-work {
  width: 100%;
  border-spacing: 0;
}
table.cm-table-work th {
  background-color: #c71066;
  color: #ffffff;
  font-size: 0.625rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 1.5em;
  height: 50px;
  vertical-align: middle;
  text-align: center;
  padding: 0 14px;
}
table.cm-table-work th:first-child {
  text-align: left;
}
table.cm-table-work th span {
  display: block;
  max-width: 140px;
}
table.cm-table-work td {
  padding: 22px 14px;
  font-size: 0.875rem;
  font-weight: 300;
  text-align: center;
}
table.cm-table-work td:first-child {
  text-align: left;
}
table.cm-table-work tr:nth-child(2n+1) {
  background-color: #f6f6f6;
}

@media screen and (max-width: 991px) {
  .cm-work-list__item {
    padding: 16px 0;
  }
  .cm-work-list__item--header {
    display: none;
  }
  .cm-work-list__row {
    flex-direction: column;
    align-items: flex-start;
  }
  .cm-work-list__col {
    padding: 6px 14px;
  }
  .cm-work-list__col::before {
    content: attr(data-title) ":";
    display: inline-block;
    margin-right: 6px;
    font-weight: 500;
  }
}
@media screen and (min-width: 992px) {
  .cm-work-list__col:first-child {
    text-align: left;
  }
  .cm-work-list__col--position, .cm-work-list__col--type {
    width: 100%;
  }
  .cm-work-list__col--place, .cm-work-list__col--date {
    width: 160px;
  }
}
.cm-products {
  display: flex;
  flex-wrap: wrap;
}
.cm-products__item {
  display: flex;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 20px;
}
@media screen and (min-width: 576px) {
  .cm-products__item {
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .cm-products__item {
    width: 33.33%;
  }
}
.cm-products__item--s {
  width: 100%;
}
@media screen and (min-width: 576px) {
  .cm-products__item--s {
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .cm-products__item--s {
    width: 25%;
  }
}

.cm-products-footer {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.cm-product-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  overflow: hidden;
}
.cm-product-box__image {
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;
}
.cm-product-box__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cm-product-box__tag {
  position: absolute;
  top: 14px;
  left: 14px;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 6px 20px;
  border-radius: 20px;
  background-color: #97BF26;
  color: #ffffff;
  z-index: 2;
}
.cm-product-box__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.cm-product-box__title {
  font-size: 1.125rem;
  line-height: 1.25em;
  font-weight: 400;
  margin-bottom: 20px;
}
.cm-product-box__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}
.cm-product-box__price {
  margin-top: auto;
  font-size: 1rem;
  font-weight: 300;
}
.cm-product-box__price span {
  margin-right: 10px;
}
.cm-product-box__price span:last-child {
  margin-right: 0;
}
.cm-product-box .amount {
  font-size: 1.5rem;
  font-weight: 600;
  color: #c71066;
}
.cm-product-box:hover {
  background-color: #F6F6F6;
}

.product-btn {
  min-width: 44px;
  width: 44px;
  height: 44px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ed6ea6;
  border-radius: 50%;
  overflow: hidden;
}
.product-btn:hover {
  background-color: #c71066;
}
.product-btn svg {
  max-width: 20px;
}
.product-btn svg path {
  fill: #ffffff;
}

.wapf-field-label {
  font-size: 1.5rem;
  letter-spacing: 0.02em;
  font-weight: 300;
  margin-bottom: 24px;
}

.wapf-checkable {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.wapf-checkable label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.wapf-checkable label:hover input[type=checkbox]::after {
  transform: scale(1);
  background-color: #DEDEDE;
}
.wapf-checkable input[type=checkbox] {
  width: 18px;
  min-width: 18px;
  height: 18px;
  background-color: #DEDEDE;
  margin-right: 20px;
  border-radius: 2px;
  border-color: #DEDEDE;
  overflow: hidden;
}
.wapf-checkable input[type=checkbox]::after {
  width: 18px;
  min-width: 18px;
  height: 18px;
  background-image: url(../../assets/checked-white.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
  background-color: #97BF26;
  transition: all 0s;
}
.wapf-checkable input[type=checkbox]:checked:hover::after {
  background-color: #97BF26;
}

.wapf--inner {
  padding: 20px 0;
}
.wapf--inner span {
  display: none;
}
.wapf--inner span.wapf-grand-total {
  display: block;
}

.cmo-header {
  position: relative;
  margin-bottom: 80px;
}
.cmo-header__image {
  height: 330px;
  max-width: 1920px;
  margin: 0 auto 80px;
  position: relative;
}
.cmo-header__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cmo-header__content {
  margin-bottom: 40px;
}
.cmo-header__title {
  color: #c71066;
  margin-bottom: 20px;
}
.cmo-header__grid {
  position: relative;
}

@media screen and (min-width: 1200px) {
  .cmo-header__content {
    width: calc(100% - 480px);
    padding-right: 160px;
    margin-bottom: 0;
  }
  .cmo-header__form {
    position: absolute;
    top: -360px;
    right: 0;
    width: 480px;
  }
}
.cmo-about {
  background-color: #f6f6f6;
  padding: 60px 0 40px;
}
.cmo-about__title {
  margin-bottom: 30px;
}
.cmo-about__info {
  color: #c71066;
  margin-bottom: 60px;
}

@media screen and (min-width: 1200px) {
  .cmo-about {
    padding: 80px 0;
  }
  .cmo-about__desc {
    width: calc(100% - 480px);
    padding-right: 160px;
  }
  .cmo-about__title {
    margin-bottom: 60px;
  }
}
.cmo-info {
  background-color: #f6f6f6;
  margin-top: 100px;
  overflow: visible;
}
.cmo-info__row {
  transform: translateY(-100px);
}
.cmo-info__desc {
  padding-top: 40px;
}
.cmo-info__title {
  margin-bottom: 40px;
}

@media screen and (min-width: 1200px) {
  .cmo-info__row {
    display: flex;
    align-items: flex-end;
  }
  .cmo-info__image, .cmo-info__desc {
    width: 50%;
  }
  .cmo-info__desc {
    padding-left: 100px;
    padding-top: 0;
  }
}
.page404 {
  padding-top: 60px;
}
.page404__header span {
  margin-right: 20px;
}
.page404__desc {
  padding-top: 40px;
}
.page404__search {
  margin-bottom: 30px;
}
.page404__title {
  margin-bottom: 20px;
}
.page404__list {
  max-width: 680px;
  margin-bottom: 30px;
}
.page404__list li {
  margin-bottom: 14px;
}

@media screen and (max-width: 1199px) {
  .page404__image {
    margin-top: 40px;
  }
  .page404__image img {
    margin: 0 auto;
  }
}
@media screen and (min-width: 1200px) {
  .page404__row {
    display: flex;
  }
  .page404__image {
    width: 50%;
    order: 2;
  }
  .page404__desc {
    width: 50%;
    padding-top: 40px;
    padding-right: 80px;
  }
}
.woocommerce-message {
  background-color: #ed6ea6;
  padding: 15px 20px;
  color: #fff;
  margin-bottom: 30px;
  font-size: 0.875rem;
  font-weight: 300;
}
.woocommerce-message a {
  display: inline-block;
  font-weight: 600;
  margin-right: 4px;
}
.woocommerce-message a:hover {
  text-decoration: underline;
}

.wc-cart__content {
  margin-bottom: 40px;
}

@media screen and (min-width: 1200px) {
  .wc-cart {
    display: flex;
    align-items: flex-start;
  }
  .wc-cart__content {
    padding-right: 16px;
    width: 66%;
    margin-bottom: 0;
  }
  .wc-cart__info {
    width: 34%;
  }
}
.cart-table__item {
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #F2F2F2;
}
.cart-table__item--header {
  text-transform: uppercase;
  padding-left: 60px;
  font-weight: 500;
  min-height: 66px;
}
.cart-table__product {
  display: flex;
  align-items: center;
}
.cart-table__thumb {
  display: inline-flex;
  min-width: 100px;
  width: 100px;
  height: 100px;
  position: relative;
  margin-right: 24px;
}
.cart-table__thumb img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cart-table__data {
  display: flex;
  align-items: center;
}
.cart-table__title {
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  color: #c71066;
  margin-bottom: 6px;
}
.cart-table__args {
  font-size: 0.875rem;
  font-weight: 300;
}
.cart-table__args dt {
  display: none;
}
.cart-table__total .amount {
  color: #c71066;
}

@media screen and (max-width: 767px) {
  .cart-table__item {
    position: relative;
  }
  .cart-table__item--header {
    display: none;
  }
  .cart-table__actions {
    position: absolute;
    left: 0;
    bottom: 34px;
  }
  .cart-table__product {
    margin-bottom: 10px;
  }
  .cart-table__data {
    justify-content: flex-end;
  }
  .cart-table__quantity {
    margin: 0 20px;
  }
}
@media screen and (min-width: 768px) {
  .cart-table__item {
    display: flex;
    align-items: center;
  }
  .cart-table__actions {
    width: 60px;
    min-width: 60px;
  }
  .cart-table__product {
    flex-grow: 1;
    padding-right: 20px;
  }
  .cart-table__price, .cart-table__total {
    width: 80px;
    min-width: 80px;
  }
}
@media screen and (min-width: 768px) and (min-width: 992px) {
  .cart-table__price, .cart-table__total {
    width: 100px;
    min-width: 100px;
  }
}
@media screen and (min-width: 768px) {
  .cart-table__quantity {
    width: 120px;
    min-width: 120px;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.quantity {
  display: flex;
  align-items: stretch;
}
.quantity.hidden {
  display: none;
}
.quantity label {
  display: none;
}
.quantity__btns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.quantity input {
  width: 50px;
  height: 46px;
  text-align: center;
  border: 1px solid #BBBBBB;
  appearance: none;
  margin-right: 6px;
}

.btn-quantity {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid #BBBBBB;
}
.btn-quantity:hover {
  background-color: #bbbbbb;
}
.btn-quantity::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}
.btn-quantity--inc::after {
  border-width: 0 4px 4px 4px;
  border-color: transparent transparent #bbbbbb transparent;
}
.btn-quantity--inc:hover::after {
  border-color: transparent transparent #ffffff transparent;
}
.btn-quantity--dec::after {
  border-width: 4px 4px 0 4px;
  border-color: #bbbbbb transparent transparent transparent;
}
.btn-quantity--dec:hover::after {
  border-color: #ffffff transparent transparent transparent;
}

.cart-info-box {
  background-color: #F6F6F6;
  margin-bottom: 20px;
  width: 100%;
}
.cart-info-box__header {
  padding: 20px 20px;
  border-bottom: 2px solid #fff;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.cart-info-box__content {
  padding: 20px 20px 30px;
}
.cart-info-box__text {
  padding: 10px 0 20px;
  font-size: 1rem;
  line-height: 1.5em;
  color: #BBBBBB;
}
.cart-info-box button, .cart-info-box a {
  width: 100%;
}

#coupon_code {
  margin-bottom: 10px;
}

@media screen and (min-width: 576px) {
  .cart-info-box__header {
    padding: 20px 40px;
  }
  .cart-info-box__content {
    padding: 20px 40px 30px;
  }
}
.cart-summary__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.wc-info {
  padding: 60px 0 100px;
  text-align: center;
}
.wc-info__header {
  text-transform: uppercase;
  margin-bottom: 40px;
}

.wc-checkout__customer {
  margin-bottom: 40px;
}
.wc-checkout__title {
  text-transform: uppercase;
  font-weight: 500;
  min-height: 66px;
  border-bottom: 1px solid #F2F2F2;
  margin-bottom: 30px;
  padding-top: 20px;
}

.checkout-box {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #F2F2F2;
}
.checkout-box--last {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
.checkout-box:empty {
  display: none;
}
.checkout-box__title {
  margin-bottom: 20px;
  font-weight: 500;
}
.checkout-box ul li {
  margin-bottom: 20px;
}
.checkout-box ul li:last-child {
  margin-bottom: 0;
}

.shipping-method li {
  display: flex;
  align-items: center;
}
.shipping-method li input {
  margin-right: 16px;
}

div.payment-metchod {
  display: flex;
  align-items: center;
}
div.payment-metchod input {
  margin-right: 16px;
}

.payment_box {
  padding-left: 32px;
  padding-top: 10px;
  line-height: 1.375em;
}

.form-check {
  display: flex;
  margin-bottom: 16px;
  line-height: 1.375em;
}
.form-check input {
  margin-right: 16px;
}

.checkout-submit {
  padding-top: 16px;
}

.woocommerce-billing-fields {
  margin-bottom: 20px;
}

@media screen and (min-width: 1200px) {
  .wc-checkout {
    display: flex;
    margin: 0 -28px;
  }
  .wc-checkout__customer {
    width: 50%;
    padding: 0 28px;
  }
  .wc-checkout__info {
    width: 50%;
    padding: 0 28px;
  }
}
.form-fields .form-field label {
  display: none;
}

@media screen and (min-width: 768px) {
  .form-fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;
  }
  .form-fields .form-field {
    width: 50%;
    padding: 0 7px;
  }
  .form-fields .form-field--full {
    width: 100%;
  }
}
.review-order-list__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.cm-doc {
  padding-top: 40px;
}
.cm-doc__item {
  margin-bottom: 80px;
}
.cm-doc__header {
  margin-bottom: 40px;
}
.cm-doc__text {
  margin-bottom: 30px;
}

.tp {
  background-color: #F6F6F6;
  padding-top: 240px;
  margin-top: -260px;
  padding-bottom: 100px;
  margin-bottom: 100px;
}
.tp__header {
  text-align: center;
  margin-bottom: 50px;
}

.tp-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 -20px;
}
.tp-list__item {
  width: 100%;
  padding: 0 10px;
  display: flex;
  margin-bottom: 20px;
}
@media screen and (min-width: 1200px) {
  .tp-list__item {
    width: 33.33%;
  }
}

.tp-about {
  max-width: 1200px;
  margin: 0 auto;
}

.cm-package-box {
  background-color: #ffffff;
  padding: 50px 40px;
  border: 1px solid #DEDEDE;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.cm-package-box__header {
  margin-bottom: 50px;
}
.cm-package-box__tag {
  font-size: 1.25rem;
  margin-bottom: 6px;
}
.cm-package-box__list {
  margin-bottom: 40px;
}
.cm-package-box__footer {
  margin-top: auto;
}

.wc-login__col {
  margin-bottom: 30px;
}

@media screen and (min-width: 992px) {
  .wc-login {
    display: flex;
    justify-content: center;
    margin: 0 -28px;
  }
  .wc-login__col {
    width: 50%;
    max-width: 600px;
    padding: 0 28px;
    display: flex;
    margin-bottom: 0;
  }
}
.my-account__nav {
  margin-bottom: 40px;
}

.woocommerce-MyAccount-navigation-link.is-active {
  font-weight: 600;
}

@media screen and (min-width: 1200px) {
  .my-account {
    display: flex;
  }
  .my-account__nav {
    min-width: 340px;
    width: 340px;
  }
  .my-account__content {
    flex-grow: 1;
    padding-left: 40px;
  }
}
@media screen and (min-width: 1440px) {
  .my-account__nav {
    min-width: 420px;
    width: 420px;
  }
  .my-account__content {
    padding-left: 60px;
  }
}
.account-adress-list__item {
  margin-bottom: 20px;
}
.account-adress-list address {
  line-height: 1.5em;
  font-size: 1rem;
  margin-bottom: 30px;
}

@media screen and (min-width: 992px) {
  .account-adress-list {
    display: flex;
    margin: 0 -8px;
  }
  .account-adress-list__item {
    width: 50%;
    padding: 0 8px;
    display: flex;
    margin-bottom: 0;
  }
  .account-adress-list .cart-info-box {
    display: flex;
    flex-direction: column;
  }
  .account-adress-list .cart-info-box__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .account-adress-list .cart-info-box__action {
    margin-top: auto;
  }
}
.woocommerce-orders-table {
  width: 100%;
}
.woocommerce-orders-table th {
  padding: 20px 0;
  border-bottom: 1px solid #F2F2F2;
  text-align: left;
}
.woocommerce-orders-table td {
  padding: 20px 0;
  border-bottom: 1px solid #F2F2F2;
}

@media screen and (max-width: 991px) {
  .woocommerce-orders-table thead {
    display: none;
  }
  .woocommerce-orders-table tr {
    display: block;
    border-top: 1px solid #F2F2F2;
    padding: 10px 0;
  }
  .woocommerce-orders-table td {
    display: block;
    border: none;
    padding: 10px 0;
  }
  .woocommerce-orders-table td::before {
    content: attr(data-title) ":";
    display: inline-block;
    margin-right: 16px;
    font-weight: 500;
  }
  .woocommerce-orders-table td.woocommerce-orders-table__cell-order-actions::before {
    content: none;
  }
}
.cm-declaration__header {
  background-color: #F6F6F6;
  padding-top: 60px;
  margin-top: -60px;
  padding-bottom: 60px;
  margin-bottom: 80px;
}
.cm-declaration__title {
  margin-bottom: 50px;
  text-align: center;
}
.cm-declaration__item {
  display: none;
}
.cm-declaration__item.active {
  display: block;
}
.cm-declaration__desc {
  max-width: 1360px;
  margin: 0 auto 80px auto;
}
.cm-declaration__name {
  margin-bottom: 40px;
  text-align: center;
}

.cm-declaration-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 -7px;
}
.cm-declaration-nav__item {
  display: flex;
  width: 100%;
  max-width: 574px;
  padding: 0 7px;
  margin-bottom: 14px;
}
@media screen and (min-width: 1200px) {
  .cm-declaration-nav__item {
    width: 33.33%;
    max-width: 574px;
  }
}

.btn-declaration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px;
  background-color: #ffffff;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  line-height: 1.5em;
  font-weight: 500;
  text-align: left;
}
.btn-declaration:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.btn-declaration span {
  max-width: 340px;
}
.btn-declaration svg {
  width: 26px;
  min-width: 26px;
  margin-left: 30px;
}
.btn-declaration.active {
  color: #c71066;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.declaration-form {
  max-width: 1200px;
  margin: 0 auto;
}
.declaration-form__header {
  padding: 20px 30px;
  text-transform: uppercase;
  background-color: #f6f6f6;
  margin-top: 40px;
  border: 1px solid #dedede;
}
.declaration-form__group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  background-color: #f6f6f6;
  padding: 26px 16px 6px;
  border-bottom: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
}
.declaration-form__title {
  width: 100%;
  font-weight: 500;
  margin-bottom: 26px;
  font-size: 0.875rem;
  padding-left: 14px;
}
.declaration-form__field {
  padding: 0 7px;
  margin-bottom: 20px;
}
.declaration-form__label {
  padding: 0 0 4px 24px;
  font-size: 0.75rem;
  font-weight: 500;
}
.declaration-form__choose {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}
.declaration-form__choose input {
  margin-right: 8px;
}
.declaration-form__info {
  padding-top: 40px;
  font-size: 0.875rem;
  line-height: 1.5em;
  margin-bottom: 40px;
}
.declaration-form__info h3 {
  margin-bottom: 16px;
}
.declaration-form__info p {
  margin-bottom: 10px;
}
.declaration-form__footer {
  display: flex;
  justify-content: center;
}
.declaration-form sup {
  vertical-align: super;
}
.declaration-form ul {
  margin-bottom: 14px;
}
.declaration-form ul li {
  padding-left: 14px;
  margin-top: 10px;
}

@media screen and (max-width: 991px) {
  .declaration-form__header {
    padding: 16px 20px;
    margin-top: 20px;
  }
  .declaration-form__field {
    width: 100% !important;
    padding-left: 7px !important;
  }
  .declaration-form__group {
    padding: 20px 4px 4px;
  }
}