@use "../variables" as variable;

.scrollbar-outer {
    height: 380px;
}

.scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
    width: 5px;
}

.scrollbar-outer > .scroll-element .scroll-element_track {
    width: 1px;
    background-color: #dedede;
    left: 2px;
}

.scrollbar-outer > .scroll-element.scroll-y .scroll-bar {
    background-color: variable.$secondary;
    cursor: pointer;
}