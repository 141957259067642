@use "../variables" as variable;

.cm-box-visit {
    padding: 30px 20px;
    background-color: variable.$secondary;
    background-image: linear-gradient(155deg, #c71066 0%, #ed6ea6 100%);
    color: #ffffff;

    &__img {
        max-width: 50px;
        margin-bottom: 16px;
    }

    &__title {
        margin-bottom: 20px;
    }

    &__text {
        font-size: .875em;
        line-height: 1.75em;
        font-weight: 300;
        margin-bottom: 30px;
    }

    &__action {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;

        a {
            margin: 0 5px 5px;
        }
    }

    &__info {
        padding-top: 30px;
        font-size: .75rem;
        line-height: 1.5em;
    }
}


@include variable.media(575px, max) {
    .cm-box-visit {
        &__action {
            flex-wrap: wrap;

            a {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
}


@include variable.media(576px, min) {
    .cm-box-visit {
        padding: 50px 40px;

        &__img {
            max-width: 100%;
            margin-bottom: 30px;
        }
    }
}


@include variable.media(1440px, min) {
    .cm-box-visit {
        padding: 50px 60px;
    }
}