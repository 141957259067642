@use "../../variables" as variable;


.review-order-list {
    
    &__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
    }
}