@use "../variables" as variable;

.cm-slider {
    display: flex;
    position: relative;
    width: 100%;
    overflow: hidden;

    .swiper-wrapper, .swiper-slide {
        display: flex;
        width: 100%;
        height: 100% !important;
    }

    &__item {
        width: 100%;
        position: relative;
        display: flex;
        min-height: 360px;
    }

    &__img {
        @include variable.cover();
        object-fit: cover;
    }

    &__box {
        width: 100%;
        padding: 30px 40px 10px;
        background-color: rgba(255,255,255,.9);
        border-radius: variable.$box_radius;
        position: relative;
    }


    &__title {
        margin-bottom: 20px;
        color: variable.$secondary;
    }

    &__text {
        margin-bottom: 30px;
    }
}


.cm-slider-nav {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    pointer-events: none;

    &__btn {
        width: 35px;
        height: 60px;
        border: 1px solid #dedede;
        background-color: #ffffff;
        @include variable.center();
        pointer-events: all;
    }
}


@include variable.media(768px, min) {
    .cm-slider {
        &__item {
            padding: 30px 76px;   
            min-height: 460px;
            align-items: center;
        }

        &__box {
            max-width: 620px;
            padding: 30px 40px;
        }

        &__title {
            margin-bottom: 26px;
        }

        &__text {
            margin-bottom: 40px;
        }
    }
}