@use "../variables" as variable;

.cm-gallery {
    margin-bottom: 80px;

    &__content {
        padding: 40px 0;
        border-top: 1px solid variable.$grey;
        border-bottom: 1px solid variable.$grey;
    }


    &__title {
        color: variable.$secondary;
        margin-bottom: 36px;
    }
}

.cm-gallery-slider {
    overflow: hidden;
    position: relative;

    &__item {
        position: relative;
        padding-bottom: 76%;

        img {
            @include variable.cover();
            object-fit: cover;
        }
    }
}