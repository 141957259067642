@use "../../variables" as variable;

.header {
	width: 100%;
	position: absolute;
	z-index: 11;
	//overflow: hidden;
	display: flex;
	align-items: center;
	height: 134px;
	border-bottom: 1px solid #dedede;
	transition: variable.$transition;
	background-color: #ffffff;
	
	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__contact {
		display: flex;
		align-items: center;

		a {
			margin-left: 20px;

			@include variable.media(1360px, min) {
				margin-left: 54px;
			}
		}
	}
}

.hamburger {
	$size: 40px;
	width: $size;
	height: $size;
	z-index: 100;
	position: relative;
	cursor: pointer;
	margin-left: auto;
	overflow: hidden;
	display: inline-flex;

	svg {
		position: absolute;
		right: -15%;
		height: 100%;
		transform-origin: top right;
	}

	path {
		fill: none;
		stroke: variable.$secondary;
		stroke-width: 3;
		stroke-linecap: round;
		stroke-linejoin: round;
		--length: 24;
		--offset: -38;
		stroke-dasharray: var(--length) var(--total-length);
		stroke-dashoffset: var(--offset);

		&:nth-child(1),
		&:nth-child(3) {
			--total-length: 126.64183044433594;
		}

		&:nth-child(2) {
			--total-length: 70;
		}
	}
}



.nav-toggled {

	.nav {
		transform: translateX(0);
		opacity: 1;
	}

	.hamburger {
		path {
			stroke: variable.$secondary;

			&:nth-child(1),
			&:nth-child(3) {
				--length: 22.627416998;
				--offset: -94.1149185097;
			}

			&:nth-child(2) {
				--length: 0;
				--offset: -50;
			}
		}
	}
}


@include variable.media(1199px, max) {
	.header {
		height: 80px;

		&__branding {
			max-width: 120px;
			margin-right: auto;
		}

		&__contact {
			display: none;
		}
	}
}


@include variable.media(1200px, min) {
	.hamburger {
		display: none;
	}
}