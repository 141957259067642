@use "../variables" as variable;

.cm-box-desc {

    &__header {
        margin-bottom: 40px;
    }

    &__title {
        color: variable.$secondary;
        margin-bottom: 16px;

        &--dark {
            color: variable.$primary;
        }
    }

    &__info {
        font-size: 1.125rem;
        line-height: 1.25em;
        font-weight: 300;
        color: variable.$secondary;

        &--dark {
            color: variable.$primary;
        }
    }

    &__text {
        margin-bottom: 40px;
    }

    &__action {
        display: flex;
        margin: 0 -10px;

        a, button {
            margin: 0 10px;
        }
    }
}

@include variable.media(575px, max) {
    .cm-box-desc {
        &__action {
            flex-wrap: wrap;

            a {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
}


@include variable.media(1200px, min) {
    .cm-box-desc {
        &__info {
            font-size: 1.875rem;
        }
    }
}