
.search-box {
    background-color: #ffffff;
    border:1px solid #DEDEDE;
    padding: 80px 0;
    box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.2);

    &__form {
        max-width: 1000px;
        margin: 0 auto;
    }
}