@use "../variables" as variable;

.cm-offer-slider {
    // padding: 10px;
    // margin: 0 -10px;
    overflow: hidden;

    .swiper-wrapper {
        align-items: stretch;
    }

    .swiper-slide {
        display: flex;
    }
    
    &__item {
        width: 100%;
        padding: 20px;
        border: 1px solid #dedede;
        background-color: #ffffff;
        border-radius: variable.$box_radius;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: 260px;

        &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        }
    }

    &__title {
        margin-bottom: 20px;
        color: variable.$secondary;
    }

    &__text {
        margin-bottom: 20px;
    }

    &__icon {
        margin-top: auto;
    }
}


.cm-offer-slider-scrollbar.swiper-scrollbar {
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    height: 6px;
    border-radius: 5px;
    background-color: transparent;
    margin-top: 20px;
    z-index: 2;

    &::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #dedede;
    }

    .swiper-scrollbar-drag {
        background-color: variable.$secondary;
        border-radius: 5px;
        cursor: pointer;
    }
}


@include variable.media(576px, min) {
    .cm-offer-slider {
        &__item {
            min-height: 340px;
            padding: 30px;
        }

        &__title {
            margin-bottom: 30px;
        }

        &__text {
            margin-bottom: 30px;
        }
    }

    .cm-offer-slider-scrollbar.swiper-scrollbar {
        margin-top: 40px;
    }
}