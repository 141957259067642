@use "../../variables" as variable;

.cmh-about {

    // &__desc {
    //     margin-bottom: 40px;
    // }

    &__image {
        display: none;
    }
}


@include variable.media(1200px, min) {
    .cmh-about {
        display: flex;
        justify-content: space-between;

        &__desc, &__image {
            width: 50%;
        }

        &__desc {
            max-width: 700px;
            padding-right: 60px;
        }

        &__image {
            display: block;
            border-radius: variable.$box-radius;
            overflow: hidden;
        }
    }
}