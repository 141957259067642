@use "../../variables" as variable;

.wapf-field-label {
    font-size: 1.5rem;
    letter-spacing: 0.02em;
    font-weight: 300;
    margin-bottom: 24px;
}


.wapf-checkable {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    label {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            input[type=checkbox]::after {
                transform: scale(1);
                background-color: #DEDEDE;
            }
        }
    }

    input[type=checkbox] {
        width: 18px;
        min-width: 18px;
        height: 18px;
        background-color: #DEDEDE;
        margin-right: 20px;
        border-radius: 2px;
        border-color: #DEDEDE;
        overflow: hidden;

        &::after {
            width: 18px;
            min-width: 18px;
            height: 18px;
            background-image: url(../../assets/checked-white.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 10px;
            background-color: #97BF26;
            transition: all 0s;
        }

        &:checked:hover::after  {
            background-color: #97BF26;
        }
    }
}


.wapf--inner {
    padding: 20px 0;
    
    span {
        display: none;

        &.wapf-grand-total {
            display: block;
        }
    }
}