@use "../../variables" as variable;

.blog-nav {
    background-image: linear-gradient(92deg, #c71066 0%, #ed6ea6 100%);
    margin-top: -40px;
    margin-bottom: 50px;
    padding-bottom: 0;
    
    @include variable.media(576px, min) {
        margin-top: -60px;
        margin-bottom: 80px;
        padding-bottom: 20px;
    }

    @include variable.media(1200px, min) {
        margin-top: -80px;
        margin-bottom: 80px;
        padding-bottom: 0;
    }

    .menu {
        &__item {
            & > a {
                color: #ffffff;
                font-weight: 500;

                svg path {
                    fill: #ffffff;
                }
            }
        }
    }
}

@include variable.media(1199px, max) {
    .blog-nav {
        .submenu {
            &__item {
                &::before {
                    content: none;
                }
            }
        }
    }
}