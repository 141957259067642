@use "../variables" as variable;

.cm-post-tabs {
    padding-top: 20px;

    &__item {
        margin-bottom: 10px;
    }

    .cm-box-link {
        width: 100%;
    }
}


@include variable.media(992px, min) {
    .cm-post-tabs {
        display: flex;
        justify-content: center;
        margin: 0 -20px;

        &__item {
            display: flex;
            width: 50%;
            max-width: 620px;
            padding: 0 20px;
            margin-bottom: 0;
        }
    }
}