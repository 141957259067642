/*
* Sets element media query
*/
@mixin media($breakpoint, $type: max) {
	@media screen and (#{$type}-width: #{$breakpoint}) {
		@content;
	}
}


/*
* Fills relative parent width and height
*/
@mixin cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
}


/*
* Centers element by type
*/
@mixin center($type: flex) {
	@if $type=='flex' {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@else {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}