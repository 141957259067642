@use "../variables" as variable;

.cm-box-info {
    border-radius: variable.$box_radius;
    border: 1px solid variable.$grey;
    margin-bottom: 30px;
    padding: 20px;

    @include variable.media(1440px, min) {
        padding: 30px 40px;
    }

    &--dark {
        background-color: #f6f6f6;
    }

    &__title {
        color: variable.$secondary;
        margin-bottom: 26px;
    }

    &__text {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.875em;
        text-transform: none;
        text-decoration: none;
        letter-spacing: 0.01em;

        p {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        b, strong {
            font-weight: 700;
        }

        a {
            //color: variable.$secondary;

            &.active {
                color: variable.$secondary;
                font-weight: 600;
            }

            &:hover {
                color: variable.$light;
            }
        }

        a[href^="tel:"], a[href^="mailto:"], a[href^="https://www.google.com/maps/"] {
            display: flex;
            align-items: center;
            margin: 5px 0;
            color: variable.$primary;

            &::before {
                content: "";
                width: 16px;
                min-width: 16px;
                height: 18px;
                margin-right: 12px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            &:hover {
                color: variable.$light;
                text-decoration: underline;
            }
        }

        a[href^="tel:"]::before {
            background-image: url(../../assets/link-tel.svg);
        }

        a[href^="mailto:"]::before {
            background-image: url(../../assets/link-mailto.svg);
        }

        a[href^="https://www.google.com/maps/"]::before {
            background-image: url(../../assets/link-place.svg);
        }

        // br {
        //     display: none;
        // }

        hr {
            border-top: 1px solid variable.$grey;
            margin-bottom: 20px;
        }
    }
}