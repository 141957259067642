@use "../../variables" as variable;

.cmo-header {
    position: relative;
    margin-bottom: 80px;

    &__image {
        height: 330px;
        max-width: 1920px;
        margin: 0 auto 80px;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        margin-bottom: 40px;
    }

    &__title {
        color: variable.$secondary;
        margin-bottom: 20px;
    }

    &__grid {
        position: relative;
    }
}


@include variable.media(1200px, min) {
    .cmo-header {
        &__content {
            width: calc(100% - 480px);
            padding-right: 160px;
            margin-bottom: 0;
        }

        &__form {
            position: absolute;
            top: -360px;
            right: 0;
            width: 480px;
        }
    }
}