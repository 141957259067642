@use "../../variables" as variable;

.cm-products {
    display: flex;
    flex-wrap: wrap;

    &__item {
        display: flex;
        width: 100%;
        padding: 0 10px;
        margin-bottom: 20px;

        @include variable.media(576px, min) {
            width: 50%;
        }

        @include variable.media(1200px, min) {
            width: 33.33%;
        }


        &--s {
            width: 100%;

            @include variable.media(576px, min) {
                width: 50%;
            }

            @include variable.media(1200px, min) {
                width: 25%;
            }
        }
    }
}


.cm-products-footer {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}


.cm-product-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    overflow: hidden;

    &__image {
        position: relative;
        padding-bottom: 75%;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__tag {
        position: absolute;
        top: 14px;
        left: 14px;
        font-size: .75rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        padding: 6px 20px;
        border-radius: 20px;
        background-color: #97BF26;
        color: #ffffff;
        z-index: 2;
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    &__title {
        font-size: 1.125rem;
        line-height: 1.25em;
        font-weight: 400;
        margin-bottom: 20px;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
    }

    &__price {
        margin-top: auto;
        font-size: 1rem;
        font-weight: 300;

        span {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .amount {
        font-size: 1.5rem;
        font-weight: 600;
        color: variable.$secondary;
    }

    &:hover {
        background-color: #F6F6F6;
    }
}

.product-btn  {
    min-width: 44px;
    width: 44px;
    height: 44px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: variable.$light;
    border-radius: 50%;
    overflow: hidden;

    &:hover {
        background-color: variable.$secondary;
    }

    svg {
        max-width: 20px;
        
        path {
            fill: #ffffff;
        }
    }
}