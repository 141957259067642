@use "../../variables" as variable;

.cm-doc {
    padding-top: 40px;
    
    &__item {
        margin-bottom: 80px;
    }

    &__header {
        margin-bottom: 40px;
    }

    &__text {
        margin-bottom: 30px;
    }
}