@use "../variables" as variable;

.cm-post {
    margin-bottom: 30px;

    &__image {
        position: relative;
        padding-bottom: 37.5%;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 30px;

        img {
            @include variable.cover();
            object-fit: cover;
        }
    }

    &__info {
        margin-bottom: 30px;
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: .875rem;
        font-weight: 300;
        text-transform: uppercase;
        color: #575756;
        margin-bottom: 12px;
    }

    &__date {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        img {
            margin-right: 10px;
        }

        &::after {
            content: "/";
            padding: 0 10px;
        }
    }

    &__cat {
        margin-bottom: 4px;
    }

    &__places {
        font-size: .875rem;
        font-weight: 300;

        span {
            font-weight: 500;
            padding-right: 4px;
        }

        a {
            color: variable.$light;
            
            &:hover {
                color: variable.$secondary;
                text-decoration: underline;
            }

            &:after {
                content: ", ";
            }

            &:last-child {
                &::after {
                    content: none;
                }
            }
        }
    }
}


@include variable.media(768px, min) {
    .cm-post {
        margin-bottom: 60px;

        &__info {
            margin-bottom: 50px;
        }
    }
}