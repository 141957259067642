@use "../../variables" as variable;

.my-account {
    &__nav {
        margin-bottom: 40px;
    }
}

.woocommerce-MyAccount-navigation-link.is-active {
    font-weight: 600;
}


@include variable.media(1200px, min) {
    .my-account {
        display: flex;
    
        &__nav {
            min-width: 340px;
            width: 340px;
        }
    
        &__content {
            flex-grow: 1;
            padding-left: 40px;
        }
    }
}

@include variable.media(1440px, min) {
    .my-account {
        &__nav {
            min-width: 420px;
            width: 420px;
        }
    
        &__content {
            padding-left: 60px;
        }
    }
}