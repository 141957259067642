@use "../variables" as variable;

.cm-file-list {
    &__item {
        margin-bottom: 20px;
    }
}

.file-link {
    display: flex;
    align-items: flex-start;
    font-size: 1rem;
    font-weight: 300;
    color: variable.$primary;
    letter-spacing: 0.01em;
    line-height: 1.5em;

    svg {
        margin-right: 16px;

        path {
            fill: variable.$light;
        }
    }

    &:hover {
        color: variable.$light;
        text-decoration: underline;
    }
}