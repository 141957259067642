@use "../../variables" as variable;

.wc-info {
    padding: 60px 0 100px;
    text-align: center;

    &__header {
        text-transform: uppercase;
        margin-bottom: 40px;
    }
}