@use "../variables" as variable;

.btn-contact {
    display: inline-flex;
    align-items: center;
    
    img {
        margin-right: 1rem;
    }

    &__info {
        font-size: .875rem;
        font-weight: 400;
        margin-bottom: 4px;
    }

    &__value {
        font-size: 1.125rem;
        font-weight: 700;
    }
}


.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: .875rem;
    letter-spacing: 0.05em;
    font-weight: 700;
    text-transform: uppercase;
    padding: 14px 28px;
    border-radius: 50px;

    svg {
        margin-right: 16px;
    }

    &--1 {
        background-color: variable.$secondary;
        color: #ffffff;

        &:hover {
            background-color: variable.$light;
        }
    }

    &--2 {
        border: 1px solid variable.$secondary;

        &:hover {
            background-color: variable.$light;
            color: #ffffff;
            border-color: variable.$light;

            svg path {
                fill: #ffffff;
            }
        }
    }

    &--white {
        background-color: #ffffff;
        color: variable.$primary;

        &:hover {
            background-color: variable.$light;
            color: #ffffff;
        }
    }
}

.btn-2 {
    font-size: 1rem;
    font-weight: 300;
    padding: 10px 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 50px;

    &--white {
        border-color: #ffffff;
        color: #ffffff;
    }

    &:hover {
        color: #ffffff;
        background-color: variable.$light;
    }
}


.btn-action {
    font-size: 1rem;
    font-weight: 300;
    padding: 10px 20px;
    display: inline-flex;
    align-items: center;
    border: 1px solid variable.$secondary;
    border-radius: 50px;

    svg, img {
        margin-right: 12px;
    }

    svg path {
        fill: variable.$secondary;
    }

    &:hover {
        background-color: variable.$light;
        border-color: variable.$light;
        color: #ffffff;

        svg path {
            fill: #ffffff;
        }
    }
}


.btn-search {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 42px;
    width: 42px;
    height: 42px;
    border: 1px solid #dedede;
    border-radius: 50%;

    svg {
        width: 14px;
        height: auto;

        path {
            fill: variable.$primary;
        }
    }

    &:hover {
        background-color: variable.$light;
        border-color: variable.$light;

        svg path {
            fill: #ffffff;     
        }
    }
}

.btn-shop {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 42px;
    width: 42px;
    height: 42px;
    border: 1px solid #dedede;
    border-radius: 50%;
    position: relative;

    &__value {
        position: absolute;
        bottom: 26px;
        left: 26px;
        font-size: 10px;
        background: variable.$secondary;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    svg {
        width: 14px;
        height: auto;

        path {
            fill: variable.$primary;
        }
    }

    &:hover {
        background-color: variable.$light;
        border-color: variable.$light;

        svg path {
            fill: #ffffff;     
        }
    }
}

.btn-nav {
    width: 48px;
    min-width: 48px;
    height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border:1px solid #DEDEDE;
    border-radius: 50%;

    svg, img {
        width: 18px;
    }

    &--prev {
        svg,img {
            transform: rotate(180deg);
        }
    }

    &.disable {
        svg {
            fill: #DEDEDE;
        }
    }
}

a.btn-nav {
    &:hover {
        background-color: variable.$secondary;
    
        svg {
            fill: #ffffff;
        }
    }
}


.btn-up {
    width: 38px;
    height: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DEDEDE;
    border-radius: 50%;
    position: fixed;
    right: 6px;
    bottom: 10px;
    background-color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: all .3s;

    @include variable.media(768px, min) {
        width: 44px;
        height: 44px;
        right: 6px;
        bottom: 20px;
    }

    @include variable.media(1440px, min) {
        right: 30px;
        bottom: 30px;
    }

    &.active {
        opacity: 1;
        pointer-events: all;
    }
}


@include variable.media(575px, max) {
    .btn-action {
        svg, img {
            margin-right: 0;
        }
        span {
            display: none;
        }
    }
}