@use "../../variables" as variable;


.cms-about {
   
    &__desc {
        margin-bottom: 20px;
    }

    &__info {
        margin-bottom: 60px;
    }

    &__header {
        margin-bottom: 40px;
    }

    &__title {
        color: variable.$secondary;
        margin-bottom: 4px;
    }

    &__tags {
        font-size: 1rem;
        font-weight: 700;
    }

    &__text {
        margin-bottom: 60px;
    }

    &__image {
        margin-bottom: 26px;

        img {
            width: 100%;
        }
    }

    &__form {
        max-width: 720px;
    }

    &__info-form {
        margin: 40px 0;
    }
    
}


.cms-about-work {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__logo {
        margin-bottom: 40px;
    }

    &__text {
        max-width: 860px;
        margin-bottom: 40px;
    }

    &__header {
        margin-bottom: 60px;
    }
}


@include variable.media(1200px, min) {
    .cms-about {
        &__row {
            display: flex;
            align-items: flex-start;
        }

        &__desc {
            width: 70%;
            padding-right: 40px;
            margin-bottom: 80px;
        }

        &__info {
            width: 30%;
            // width: 480px;
            // min-width: 480px;
            margin-bottom: 80px;
        }

        &--reverse {
            .cms-about {
                &__desc {
                    order: 2;
                    padding-right: 0;
                    padding-left: 40px;
                }
            }
        }
    }
}


@include variable.media(1440px, min) {
    .cms-about {
        &__desc {
            padding-right: 80px;
        }

        &--reverse {
            .cms-about {
                &__desc {
                    padding-left: 80px;
                }
            }
        }
    }
}