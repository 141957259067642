.cm-open-list {
    font-size: 1rem;
    font-weight: 300;

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}