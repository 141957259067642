.cm-filter-form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;

    &__item {
        width: 340px;
        padding: 0 8px;
        margin-bottom: 16px;
    }

    &__actions {
        display: flex;

        button, a {
            margin-left: 16px;
            margin-bottom: 16px;
        }
    }
}