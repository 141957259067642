@use "../variables" as variable;

.cm-step-list {
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 72px;
        margin-bottom: 28px;

        img {
            max-width: 100%;
            max-height: 72px;
            width: auto;
            height: auto;
        }
    }

    &__title {
        font-size: 1rem;
        line-height: 1.5em;
        font-weight: 700;
        text-align: center;
    }
}


@include variable.media(575px, max) {
    .cm-step-list {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;

            &::after {
                content: "";
                width: 1px;
                height: 30px;
                border-right: 1px dashed #b1b1b1;
                margin: 20px 0;
            }

            &:last-child::after {
                content: none;
            }
        }
    }
}


@include variable.media(576px, min) {

    .cm-step-list {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;

        &__item {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 33.33%;
            margin-bottom: 30px;
    
            @include variable.media(992px, min) {
                width: 20%;
                margin-bottom: 0;
            }
    
            &::after {
                content: "";
                width: 80px;
                padding-bottom: 36px;
                border-bottom: 1px dashed #b1b1b1;
            }
    
            &:last-child::after {
                content: none;
            }
        }

        &__content {
            max-width: 182px;
        }
    }
}