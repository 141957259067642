@use "../variables" as variable;

.cm-place-list {
    
    &__item {
        margin-bottom: 20px;

        a {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            img {
                margin-right: 12px;
            }

            strong {
                text-transform: uppercase;
                margin-right: 6px;
            }

            &:hover {
                color: variable.$light;

                strong {
                    color: variable.$secondary;
                }
            }
        }
    }
}