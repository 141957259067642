@use "../variables" as variable;


.cm-result-box {
    &__form {
        margin-bottom: 40px;
    }
}

.cm-result-list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    //margin: 0 -12px;

    &__col {
        width: 100%;
        padding: 0 12px;
        margin-bottom: 50px;

        &.hide {
            display: none;
        }

        ul li.hide {
            display: none;
        }

        @include variable.media(768px, min) {
            width: 50%;
        }

        @include variable.media(992px, min) {
            width: 33.33%;
        }

        @include variable.media(1200px, min) {
            width: 25%;
        }
    }

    &__cat {
        font-size: 1rem;
        color: #b1b1b1;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
}