@use "../../variables" as variable;

.declaration-form {
    max-width: 1200px;
    margin: 0 auto;

    &__header {
        padding: 20px 30px;
        text-transform: uppercase;
        background-color: #f6f6f6;
        margin-top: 40px;
        border: 1px solid #dedede;
    }

    &__group {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        background-color: #f6f6f6;
        padding: 26px 16px 6px;
        border-bottom: 1px solid #dedede;
        border-left: 1px solid #dedede;
        border-right: 1px solid #dedede;
    }

    &__title {
        width: 100%;
        font-weight: 500;
        margin-bottom: 26px;
        font-size: .875rem;
        padding-left: 14px;
    }

    &__field {
        padding: 0 7px;
        margin-bottom: 20px;
    }

    &__label {
        padding: 0 0 4px 24px;
        font-size: .75rem;
        font-weight: 500;
    }

    &__choose {
        display: flex;
        align-items: center;
        font-size: .875rem;

        input {
            margin-right: 8px;
        }
    }

    &__info {
        padding-top: 40px;
        font-size: 0.875rem;
        line-height: 1.5em;
        margin-bottom: 40px;

        h3 {
            margin-bottom: 16px;
        }

        p {
            margin-bottom: 10px;
        }
    }


    &__footer {
        display: flex;
        justify-content: center;
    }

    
    sup {
        vertical-align: super;
    }


    ul {
        margin-bottom: 14px;

        li {
            padding-left: 14px;
            margin-top: 10px;
        }
    }
}


@include variable.media(991px, max) {
    .declaration-form {

        &__header {
            padding: 16px 20px;
            margin-top: 20px;
        }

        &__field {
            width: 100% !important;
            padding-left: 7px !important;
        }   

        &__group {
            padding: 20px 4px 4px;
        }
    }
}