@use "../variables" as variable;

.cm-ig-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px 30px -5px;

    &__item {
        width: 50%;
        padding: 0 5px;
        margin-bottom: 10px;
    }
}

.cm-ig-post-box {
    display: block;
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 5px;

    &::after {
        content: "";
        @include variable.cover();
        background-color: variable.$secondary;
        opacity: 0;
        transition: all .3s;
    }

    img {
        @include variable.cover();
        object-fit: cover;
        transition: all .3s;
    }

    &:hover::after {
        opacity: .2;
    }
}