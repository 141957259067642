@use "../../variables" as variable;

.cmo-about {
    background-color: #f6f6f6;
    padding: 60px 0 40px;

    &__title {
        margin-bottom: 30px;
    }

    &__info {
        color: variable.$secondary;
        margin-bottom: 60px;
    }
}


@include variable.media(1200px, min) {
    .cmo-about {
        padding: 80px 0;

        &__desc {
            width: calc(100% - 480px);
            padding-right: 160px;
        }

        &__title {
            margin-bottom: 60px;
        }
    }
}