@use "../variables" as variable;

.cm-box-link {
    padding: 26px 40px;
    display: flex;
    align-items: center;
    border: 1px solid #DEDEDE;
    border-radius: 5px;

    &__icon {
        width: 44px;
        min-width: 44px;
        margin-right: 30px;
    }

    &__title {
        margin-right: 30px;
    }

    a {
        margin-left: auto;
        min-width: 140px;
    }
}


@include variable.media(575px, max) {
    .cm-box-link {
        padding: 20px 16px;
        flex-wrap: wrap;

        &__icon {
            width: 32px;
            min-width: 32px;
            margin-right: 16px;
        }

        &__title {
            margin-right: 0;
        }

        a {
            min-width: 100%;
            margin-top: 16px;
        }
    }
}