@use "../variables" as variable;

.cm-box-form {
    width: 100%;
    padding: 30px 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    border: 1px solid #b1b1b1;
    background-color: #ffffff;

    &__img {
        margin-bottom: 30px;
    }

    &__title {
        margin-bottom: 20px;
    }

    &__info {
        margin-bottom: 30px;
    }
    

    &__contact {
        border-top: 1px solid variable.$light;
        padding-top: 30px;
        margin-top: 30px;
        font-size: 1rem;

        p {
            margin-bottom: 20px;
        }

        b,strong {
            font-weight: 700;
            letter-spacing: 0.01em;
            color: variable.$secondary;
        }

        a[href^="tel:"], a[href^="mailto:"], a[href^="https://www.google.com/maps/"] {
            display: flex;
            align-items: center;
            color: variable.$primary;

            &::before {
                content: "";
                width: 22px;
                height: 22px;
                margin-right: 16px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            &:hover {
                color: variable.$light;
                text-decoration: underline;
            }
        }

        a[href^="tel:"]::before {
            background-image: url(../../assets/link-tel.svg);
        }

        a[href^="mailto:"]::before {
            background-image: url(../../assets/link-mailto.svg);
        }

        a[href^="https://www.google.com/maps/"]::before {
            background-image: url(../../assets/link-place.svg);
        }
    }
}



@include variable.media(768px, min) {
    .cm-box-form {
        padding: 50px 60px;
    }
}