@use "../variables" as variable;

.cm-partners {
    &__header {
        font-size: 0.875rem;
        font-weight: 400;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #dedede;
        position: relative;
        overflow: hidden;
        text-align: center;
        margin-bottom: 30px;

        p {
            width: max-content;
            position: relative;
            margin: 0 auto;

            &::before, &::after {
                content: "";
                position: absolute;
                top: 50%;
                width: 50vw;
                height: 1px;
                background-color: #dedede;
            }

            &::before {
                right: calc(100% + 40px);
            }

            &::after {
                left: calc(100% + 40px);
            }
        }
    }
}


.cm-partners-slider {
    overflow: hidden;

    &__item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;

        img {
            max-height: 80px;
            max-width: 220px;
            width: auto;
            height: auto;
            filter: grayscale(100%);
            opacity: 0.5;
        }

        &:hover img{
            filter: grayscale(0);
            opacity: 1;
        }
    }
}


@include variable.media(768px, min) {
    .cm-partners {
        &__header {
            margin-bottom: 60px;
        }
    }
}