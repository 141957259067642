@use "../../variables" as variable;

.cmh-blog {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    
    &__item {
        width: 100%;
        padding: 0 10px;
        margin-bottom: 20px;

        @include variable.media(576px, min) {
            width: 50%;
        }

        @include variable.media(1200px, min) {
            width: 25%;
        }
    }
}