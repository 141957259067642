@use "../../variables" as variable;

.woocommerce-orders-table {
    width: 100%;

    th {
        padding: 20px 0;
        border-bottom: 1px solid #F2F2F2;
        text-align: left;
    }

    td {
        padding: 20px 0;
        border-bottom: 1px solid #F2F2F2;
    }
}


@include variable.media(991px, max) {
    .woocommerce-orders-table {
        thead {
            display: none;
        }

        tr {
            display: block;
            border-top: 1px solid #F2F2F2;
            padding: 10px 0;
        }

        td {
            display: block;
            border: none;
            padding: 10px 0;

            &::before {
                content: attr(data-title)":";
                display: inline-block;
                margin-right: 16px;
                font-weight: 500;
            }

            &.woocommerce-orders-table__cell-order-actions {
                &::before {
                    content: none;
                }
            }
        }
    }
}