.cm-video-iframe {
    display: block;
    position: relative;
    padding-bottom: 56.4%;
    width: 100%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.cm-video-file {
    display: block;
    position: relative;
    padding-bottom: 56.4%;
    width: 100%;

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
