@use "../../variables" as variable;

.cmo-info {
    background-color: #f6f6f6;
    margin-top: 100px;
    overflow: visible;

    &__row {
        transform: translateY(-100px);
    }

    &__desc {
       padding-top: 40px;
    }

    &__title {
        margin-bottom: 40px;
    }
}


@include variable.media(1200px, min) {
    .cmo-info {
        &__row {
            display: flex;
            align-items: flex-end;
            
        }

        &__image, &__desc {
            width: 50%;
        }

        &__desc {
            padding-left: 100px;
            padding-top: 0;
        }
    }
}