@use "../../variables" as variable;

.wc-checkout {

    &__customer {
        margin-bottom: 40px;
    }


    &__title {
        text-transform: uppercase;
        font-weight: 500;
        min-height: 66px;
        border-bottom: 1px solid #F2F2F2;
        margin-bottom: 30px;
        padding-top: 20px;
    }
}


.checkout-box {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #F2F2F2;

    &--last {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    }

    &:empty {
        display: none;
    }

    &__title {
        margin-bottom: 20px;
        font-weight: 500;
    }

    ul {
        li {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}


.shipping-method {
    li {
        display: flex;
        align-items: center;
        
        input {
            margin-right: 16px;
        }
    }
}


div.payment-metchod {
    display: flex;
    align-items: center;
    
    input {
        margin-right: 16px;
    }
}

.payment_box  {
    padding-left: 32px;
    padding-top: 10px;
    line-height: 1.375em;
}


.form-check  {
    display: flex;
    margin-bottom: 16px;
    line-height: 1.375em;

    input {
        margin-right: 16px;
    }
}

.checkout-submit {
    padding-top: 16px;
}

.woocommerce-billing-fields {
    margin-bottom: 20px;
}


@include variable.media(1200px, min) {
    .wc-checkout {
        display: flex;
        margin: 0 -28px;

        &__customer {
            width: 50%;
            padding: 0 28px;
        }
    
        &__info {
            width: 50%;
            padding: 0 28px;
        }
    }
}