@use "../variables" as variable;

.cm-search {
    padding: 40px 0 20px;
    background-color: #f6f6f6;
    margin-bottom: 40px;
    margin-top: -30px;

    @include variable.media(576px, min) {
        padding: 50px 0;
        margin-top: -60px;
        margin-bottom: 60px;
    }
}