@use "../variables" as variable;


.cm-box-contact {
    max-width: 1080px;
    margin: 0 auto;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 40px 60px;
    display: flex;
    align-items: center;

    &__icon {
        margin-right: 60px;
    }

    &__title {
        margin-right: auto;
    }
}


@include variable.media(767px, max) {
    .cm-box-contact {
        padding: 30px 20px;
        flex-direction: column;

        &__icon {
            margin-right: 0;
            margin-bottom: 20px;
        }

        &__title {
            margin-left: auto;
            margin-bottom: 20px;
        }
    }
}