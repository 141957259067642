@use "../variables" as variable;

.section-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 30px;

    @include variable.media(576px, min) {
        margin-bottom: 60px;
	}
}

@include variable.media(575px, max) {
    .section-header {
        &__title {
            width: 100%;
            margin-bottom: 12px;
        }
    }
}