@use "../../variables" as variable;

.cmh-tabs {

    &__header {
        max-width: 960px;
        display: flex;
        border: 1px solid variable.$grey;
        border-bottom: none;
        border-radius: variable.$box-radius variable.$box-radius 0 0;
        background-color: #ffffff;
    }

    &__btn {
        width: 33.33%;
        font-size: 1rem;
        letter-spacing: 0.02em;
        padding: 22px 10px;
        text-align: center;
        border-right: 1px solid variable.$grey;
        
        &.active {
            font-weight: 600;
            color: variable.$secondary;
        }

        &:last-child {
            border-right: none;
        }
    }

    &__content {
        background-color: #ffffff;
        border: 1px solid variable.$grey;
        border-radius: 0 variable.$box-radius variable.$box-radius variable.$box-radius;
        padding: 30px 20px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: calc(100% + 1px);
            background-color: #ffffff;
            width: 50vw;
            height: 100%;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: calc(100% + 1px);
            background-color: #ffffff;
            width: 50vw;
            height: 100%;
        }
    }

    &__item {
        display: none;

        &.active {
            display: block;
        }
    }
}


@include variable.media(576px, min) {
    .cmh-tabs {
        &__content {
            padding: 50px;
        }
    }
}

@include variable.media(768px, min) {
    .cmh-tabs {
        &__btn {
            font-size: 1.25rem;
            padding: 32px;
        }
    }
}