@use "../variables" as variable;

.cm-article-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -15px;

    &__item {
        width: 100%;
        padding: 0 15px;
        margin-bottom: 30px;

        @include variable.media(768px, min) {
            width: 50%;
        }

        @include variable.media(992px, min) {
            width: 33.33%;
        }
    }

    &__icon {
        height: 82px;
        margin-bottom: 32px;

        img {
            max-width: 100%;
            max-height: 80px;
            width: auto;
            height: auto;
        }
    }

    &__title {
        color: variable.$secondary;
        margin-bottom: 14px;
    }
}