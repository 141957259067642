@use "../variables" as variable;

.cm-review-slider {
    margin: 0 auto;
    position: relative;
    padding: 0 20px;

    .swiper-slide {
        opacity: .4;

        &-active {
            opacity: 1;
            border: 1px solid #DEDEDE;
        }
    }
    
    &__box {
        width: 100%;
        padding: 30px 20px;
        text-align: center;
    }

    &__title {
        font-size: 1.5rem;
        font-style: italic;
        color: variable.$secondary;
        margin-bottom: 40px;
    }

    &__text {
        margin-bottom: 50px;
    }
}


@include variable.media(992px, min) {
    .cm-review-slider {
        max-width: 920px;
        padding: 0 60px;

        &__box {
            padding: 50px 40px;
        }
    }
}