@use "../variables" as variable;

.cm-awards-slider {
    position: relative;
    overflow: hidden;
    padding: 0 18px;

    .swiper-wrapper {
        align-items: stretch;
    }

    .swiper-slide {
        display: flex;
        //height: 100%;
    }

    &__item {
        padding: 20px;
        border-radius: 5px;
        border: 1px solid #dedede;

        @include variable.media(1400px, min) {
            padding: 40px;
        }
    }

    &__icon {
        height: 200px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__text {
        text-align: center;
    }
}