@use "../variables" as variable;

.cm-timeline-slider {
    position: relative;

    .swiper-slide {
        width: 400px;

        &:first-child {
            .cm-timeline-slider__point::before {
                content: none;
            }
        }
    }

    &__image {
        width: 100%;
        height: 200px;
        position: relative;
        margin-bottom: 24px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__date {
        color: variable.$secondary;
        margin-bottom: 18px;
    }

    &__point {
        display: block;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: variable.$light;
        margin-left: 50px;
        position: relative;
        margin-bottom: 20px;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            right: 100%;
            height: 1px;
            width: 100px;
            background-color: variable.$light; 
        }

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 100%;
            height: 1px;
            width: 400px;
            background-color: variable.$light;
        }
    }
}


.cm-timeline-slider-scrollbar.swiper-scrollbar {
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    height: 6px;
    border-radius: 5px;
    background-color: transparent;
    margin-top: 20px;

    @include variable.media(768px, min) {
        margin-top: 100px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #dedede;
    }

    .swiper-scrollbar-drag {
        background-color: variable.$secondary;
        border-radius: 5px;
        cursor: pointer;
    }
}