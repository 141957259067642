@use "../variables" as variable;

.wpml-ls-legacy-dropdown {
    position: relative;
    min-width: 140px;
    border: 1px solid #DEDEDE;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: .75rem;

    &:hover {
        border-radius: 20px 20px 0 0;

        .wpml-ls-sub-menu {
            display: block;
        }
    }

    a {
        display: flex;
        align-items: center;

        img {
            margin-right: 8px;
            border-radius: 4px;
        }

        &.wpml-ls-item-toggle {
            &::after {
                content: "";
                background-image: url(../../assets/icon-expand.svg);
                width: 16px;
                height: 16px;
                background-size: contain;
                background-position: center;
                margin-left: auto;
            }
        }
    }
}

.wpml-ls-sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: -1px;
    width: calc(100% + 2px);
    border: 1px solid #DEDEDE;
    border-radius: 0 0 10px 10px;
    overflow: hidden;

    a {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        background-color: #ffffff;

        &:hover {
            background-color: #f6f6f6;
        }
    }
}


@include variable.media(575px, max) {
    .wpml-ls-legacy-dropdown {
        min-width: 80px;
        padding: 10px 16px;

        .wpml-ls-native {
            display: none;
        }
    }

    .wpml-ls-sub-menu {
        a {
             padding: 10px 16px;
        }
    }
}