@use "../../variables" as variable;

.form-fields {
    .form-field {
        label {
            display: none;
        }
    }
}


@include variable.media(768px, min) {
    .form-fields {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -7px;
    
        .form-field {
            width: 50%;
            padding: 0 7px;
    
            &--full {
                width: 100%;
            }
        }
    }
}