@use "../../variables" as variable;

.header-nav {
    height: 82px;
	display: flex;
	align-items: center;
    background-color: #ffffff;
    overflow: visible;
    position: relative;

    &__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

    &__action {
        display: flex;
        align-items: center;

        a, button {
            margin-left: 10px;
        }
    }

    &__search {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 8;
        display: none;

        &.active {
            display: block;
        }
    }
}

@include variable.media(1199px, max) {
    .header-nav {
        &__row {
            justify-content: flex-end;
        }
    }

    .nav {
		position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        background: #fff;
        //padding-top: 80px;
        transform: translateY(-150%);
        box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.2);
	}
}