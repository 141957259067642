@use "../variables" as variable;

.cm-post-box {
    $box: &;
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: variable.$box-radius;
    overflow: hidden;
    background-color: transparent;
    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        @include variable.cover();
        border: 1px solid #dedede;
        border-radius: variable.$box-radius;
        transition: all .3s;
        pointer-events: none;
    }
    
    &__image {
        position: relative;
        padding-bottom: 100%;
        //overflow: hidden;

        &--small {
            padding-bottom: 50%;  
        }

        
        &--medium {
            padding-bottom: 65%;  
        }
    }

    &__img {
        @include variable.cover();
        object-fit: cover;
    }

    &__cover {
        @include variable.cover();
        @include variable.center();
        background-color: rgba(87, 87, 86, .5);
        z-index: 3;
        opacity: 0;
        transition: all .3s;
    }

    &__icon {
        width: 80px;
        height: 80px;
        border-radius: 5px;
        border: 1px solid #dedede;
        padding: 10px;
        @include variable.center();
        position: absolute;
        left: 50px;
        bottom: calc(0% - 30px);
        background-color: #ffffff;
        z-index: 4;
    }

    &__desc {
        padding: 26px 30px;
        background-color: #ffffff;

        &--big {

            @include variable.media(1560px, min) {
                padding: 50px;
            }
        }
    }

    &__title {
        font-size: 1.25rem;
        line-height: 1.5em;
        color: variable.$secondary;
        margin-bottom: 12px;
        font-weight: 600;
    }

    &__tags {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.5em;
    }

    &__category {
        text-transform: uppercase;
        margin-bottom: 20px;
        line-height: 1.375em;
        font-size: .875rem;
    }

    &:hover {
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);

        &::after {
            opacity: 0;
        }

        #{$box} {
            &__cover {
                opacity: 1;
            }
        }
    }
}


@include variable.media(992px, max) {
    .cm-post-box {
        $box: &;

    }
}