@use "../../variables" as variable;

.cmh-poz {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    border-radius: variable.$box-radius;
    overflow: hidden;

    &__desc {
        padding: 30px 20px;
    }

    &__image {
        display: none;
    }
}


@include variable.media(768px, min) {
    .cmh-poz {
        &__desc {
            padding: 80px;
        }
    }
}


@include variable.media(1200px, min) {
    .cmh-poz {
        display: flex;
        align-items: stretch;

        &__desc, &__image {
            width: 50%;
        }

        &__image {
            display: block;
            position: relative;
    
            img {   
                @include variable.cover();
                object-fit: cover;
            }
        }
    }
}