@use "../../variables" as variable;

.cm-package-box {
    background-color: #ffffff;
    padding: 50px 40px;
    border: 1px solid #DEDEDE;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    &__header {
        margin-bottom: 50px;
    }

    &__tag {
        font-size: 1.25rem;
        margin-bottom: 6px;
    }

    &__list {
        margin-bottom: 40px;
    }

    &__footer {
        margin-top: auto;
    }
}