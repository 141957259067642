@use "../variables" as variable;

.review-rate {
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
        padding: 0 3px;
    }
}