@use "../../variables" as variable;

.woocommerce-message {
    background-color: variable.$light;
    padding: 15px 20px;
    color: #fff;
    margin-bottom: 30px;
    font-size: .875rem;
    font-weight: 300;

    a {
        display: inline-block;
        font-weight: 600;
        margin-right: 4px;

        &:hover {
            text-decoration: underline;
        }
    }
}