@use "../variables" as variable;

.cm-doctor-menu {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    ///margin: 0 -20px;
    
    &__item {
        padding: 20px 20px 0;

        a {
            &:hover {
                color: variable.$light;
            }
        }
    }
}