@use "../variables" as variable;

.variations {
    margin-bottom: 30px;

    &__title {
        font-size: 0.875rem;
        margin-bottom: 10px;
    }
}


@include variable.media(768px, min) {
    .variations {
        &__item {
            display: flex;
            align-items: center;
        }

        &__title {
            margin-right: 30px;
            margin-bottom: 0;
        }
    }
}