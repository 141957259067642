@use "../../variables" as variable;

.wc-login {
    &__col {
        margin-bottom: 30px;
    }
}


@include variable.media(992px, min) {
    .wc-login {
        display: flex;
        justify-content: center;
        margin: 0 -28px;
    
        &__col {
            width: 50%;
            max-width: 600px;
            padding: 0 28px;
            display: flex;
            margin-bottom: 0;
        }
    }
}