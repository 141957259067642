@use "../../variables" as variable;

.account-adress-list {

    &__item {
        margin-bottom: 20px;
    }

    address {
        line-height: 1.5em;
        font-size: 1rem;
        margin-bottom: 30px;
    }
}


@include variable.media(992px, min) {
    .account-adress-list {
        display: flex;
        margin: 0 -8px;
    
        &__item {
            width: 50%;
            padding: 0 8px;
            display: flex;
            margin-bottom: 0;
        }

        .cart-info-box {
            display: flex;
            flex-direction: column;
    
            &__content {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            }
    
            &__action {
                margin-top: auto;
            }
        }
    }    
}