@use "../../variables" as variable;

.cart-table {
    &__item {
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid #F2F2F2;

        &--header {
            text-transform: uppercase;
            padding-left: 60px;
            font-weight: 500;
            min-height: 66px;
        }
    }


    &__product {
        display: flex;
        align-items: center;
    }

    &__thumb {
        display: inline-flex;
        min-width: 100px;
        width: 100px;
        height: 100px;
        position: relative;
        margin-right: 24px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__data {
        display: flex;
        align-items: center;
    }

    &__title {
        display: inline-block;
        font-size: 1rem;
        font-weight: 400;
        color: variable.$secondary;
        margin-bottom: 6px;
    }

    &__args {
        font-size: .875rem;
        font-weight: 300;

        dt {
            display: none;
        }
    }


    &__total {
        .amount {
            color: variable.$secondary;
        }
    }
}


@include variable.media(767px, max) {
    .cart-table {
        &__item {
            position: relative;

            &--header {
                display: none;
            }
        }

        &__actions {
            position: absolute;
            left: 0;
            bottom: 34px;
        }

        &__product {
            margin-bottom: 10px;
        }

        &__data {
            justify-content: flex-end;
        }

        &__quantity {
            margin: 0 20px;
        }
    }
}


@include variable.media(768px, min) {
    .cart-table {
        &__item {
            display: flex;
            align-items: center;
        }

        &__actions {
            width: 60px;
            min-width: 60px;
        }

        &__product { 
            flex-grow: 1;
              padding-right: 20px;
        }

        &__price, &__total {
            width: 80px;
            min-width: 80px;

            @include variable.media(992px, min) {
                width: 100px;
                min-width: 100px;
            }
        }

        &__quantity {
            width: 120px;
            min-width: 120px;
        }
    }
}