@use "../variables" as variable;

.cm-adv-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;

    &__item {
        width: 100%;
        padding: 0 30px;
        margin-bottom: 30px;

        @include variable.media(768px, min) {
            width: 50%;
            margin-bottom: 40px;
        }

        @include variable.media(1200px, min) {
            width: 33.33%;
            margin-bottom: 60px;
        }
    }

    &__box {
        position: relative;
        padding-left: 45px;

        &::before {
            content: "";
            width: 26px;
            height: 26px;
            border-radius: 5px;
            background-color: #97bf26;
            position: absolute;
            top: 5px;
            left: 0;
            background-image: url(../../assets/checked-white.svg);
            background-position: center;
            background-repeat: no-repeat;
        }
    }


}