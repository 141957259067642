@use "../variables" as variable;

.cm-contact-list {
    &__item {
        margin-bottom: 32px;
    }
}


.cm-contact-box {
    width: 100%;
    padding: 40px 30px 10px;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    &__img {
        max-width: 50px;
        max-height: 50px;
        width: auto;
        height: auto;
        margin-right: 20px;
    }

    &__info, &__desc {
        font-size: .875rem;
        font-weight: 400;
        margin-bottom: 30px;

        p {
            margin-bottom: 12px;
        }

        a {
            font-size: 1rem;
        }

        b, strong {
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.01em;
        }
    }

    &__info {
        a {
            font-size: 1.125rem;
        }
    }

    &__footer {
        margin-top: auto;
        margin-bottom: 30px;
    }
}




@include variable.media(576px, min) {

    .cm-contact-box {

        &__img {
            max-width: 60px;
            max-height: 60px;
            margin-right: 20px;
        }

        &__info {
            a {
                font-size: 1.5rem;
            }
        }
    }
}


@include variable.media(1200px, min) {
    .cm-contact-list {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        margin: 0 -8px;
    
        &__item {
            display: flex;
            width: 33.33%;
            padding: 0 8px;
            margin-bottom: 32px;
        }
    }
}


@include variable.media(1440px, min) {

    .cm-contact-list {
        margin: 0 -16px;

        &__item {
            padding: 0 16px;
        }
    }

    .cm-contact-box {
        padding: 40px 60px 10px;

        &__img {
            max-width: 80px;
            max-height: 80px;
            margin-right: 30px;
        }
    }
}