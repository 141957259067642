@use "../variables" as variable;

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;   
    padding: 30px 0;
}

.pagination-pages {
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: 1rem;
    font-weight: 300;
    color: #878787;

    @include variable.media(576px, min) {
        padding: 0 30px;
    }

    &__item {

        a {
            padding: 12px;

            &:hover {
                color: variable.$secondary;
            }
        }
        
        .current {
            min-width: 30px;
            width: 30px;
            height: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: #ffffff;
            background-color: variable.$secondary;
        }
    }
}