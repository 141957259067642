@use "../variables" as variable;

.cm-about {
    &__header {
        color: variable.$secondary;
        margin-bottom: 30px;
    }
}


.cm-about-timeline {
    padding: 60px 0;
    background-color: #f6f6f6;
}


@include variable.media(1199px, max) {
    .cm-about {
        &__desc {
            margin-bottom: 40px;
        }
    
        &__image {
            img {
                margin: 0 auto;
            }
        }
    }
}


@include variable.media(1200px, min) {
    .cm-about {
        &__row {
            display: flex;
            margin: 0 -40px;
        }
    
        &__desc {
            width: 60%;
            padding: 0 40px;
        }
    
        &__image {
            width: 40%;
            padding: 0 40px;
        }
    }
}