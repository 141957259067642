@use "../variables" as variable;


.cm-compare-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;

    &__item {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 40px;

        @include variable.media(768px, min) {
            width: 50%;
        }
    }
}




.cm-compare-box {
    &__footer {
        padding: 10px 0;
        text-align: center;
    }
}

.cd-image-container {
    position: relative;
    width: fit-content;
    max-width: 640px;
    border-radius: 8px;
    overflow: hidden;

    img {
        display: block;
    }
  }
  
  .cd-image-label {
    font-size: 0.875rem;
    font-weight: 700;
    position: absolute;
    bottom: 0;
    right: 0;
    color: #ffffff;
    padding: 20px 30px;
    //opacity: 0;
    //transform: translateY(20px);
    transition: all 0.3s;
    width: max-content;
    color: #ffffff;

    // &.is-hidden {
    //     visibility: hidden;
    // }
  }

  .is-visible .cd-image-label {
    opacity: 1;
    //transform: translateY(0);
  }
  
  .cd-resize-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    overflow: hidden;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-right: 1px solid variable.$secondary;

    img {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        height: 100%;
        width: auto;
        max-width: none;
    }

    .cd-image-label {
        right: auto;
        left: 0;
      }
  }

  .is-visible .cd-resize-img {
    width: 50%;
    /* bounce in animation of the modified image */
    animation: cd-bounce-in 0.7s;
  }
  

  @-webkit-keyframes cd-bounce-in {
    0% {
      width: 0;
    }
    60% {
      width: 55%;
    }
    100% {
      width: 50%;
    }
  }
  @-moz-keyframes cd-bounce-in {
    0% {
      width: 0;
    }
    60% {
      width: 55%;
    }
    100% {
      width: 50%;
    }
  }
  @keyframes cd-bounce-in {
    0% {
      width: 0;
    }
    60% {
      width: 55%;
    }
    100% {
      width: 50%;
    }
  }

  .cd-handle {
    position: absolute;
    height: 64px;
    width: 64px;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    border-radius: 50%;
    background-color: rgba(255,255,255,.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    //background-image: url(img/btn-compare.svg);
    background-position: center;
    background-repeat: no-repeat;
    cursor: move;
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
    padding: 10px;

    &:hover {
        background-color: rgba(255,255,255,.5);
    }
  }

  .cd-handle.draggable {
    /* change background color when element is active */
    background-color: rgba(255,255,255,.5);
  }

  .is-visible .cd-handle {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
    transition: transform 0.3s 0.7s, opacity 0s 0.7s;
  }