@use "../variables" as variable;

.cm-newsletter {
    padding: 30px 20px;
    max-width: 1440px;
    margin: 0 auto;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;

    &__desc {
        margin-bottom: 30px;
    }

    &__icon {
        max-width: 50px;
        margin-bottom: 16px;
    }

    &__info {
        font-size: 1rem;
        line-height: 1.5em;
        font-weight: 300;
    }

    &__header {
        font-size: 1rem;
        line-height: 1.5em;
        font-weight: 700;
        margin-bottom: 8px;
    }

    &.box {
        input[type=submit] {
            width: 100%;
        }
    }
}


.newsletter-form {
    &__fields {
        input[type=submit] {
            margin-top: 6px;
        }
    }
}


@include variable.media(576px, min) {
    .cm-newsletter {
        $box: &;
        padding: 40px 40px;

        &__desc {
            margin-bottom: 30px;
        }

        
        &__icon {
            max-width: 60px;
        }

        &.full {
            #{$box} {
                &__desc {
                    display: flex;
                    align-items: center;
                }

                &__icon {
                    margin-right: 20px;
                    margin-bottom: 0;
                }
            }

            .newsletter-form {
                &__fields {
                    position: relative;
            
                    input[type=submit] {
                        position: absolute;
                        top: 0;
                        right: 0;
                        margin-top: 0;
                    }
                }
            }
        }
    }
}


@include variable.media(768px, min) {
    .cm-newsletter {
        $box: &;
       
        &.full {
            padding: 60px 80px;

            #{$box} {
                &__icon {
                    max-width: 80px;
                    margin-right: 30px;
                }
            }
        }
    }
}


@include variable.media(1200px, min) {
    .cm-newsletter {
        $box: &;

        &.full {
            #{$box} {
                &__row {
                    display: flex;
                }
        
                &__desc {
                    width: 50%;
                    margin-bottom: 0;
                }
        
                &__form {
                    width: 50%;
                    padding-left: 40px;
                }
            }
        }
    }
}


@include variable.media(1380px, min) {
    .cm-newsletter {
        $box: &;

        &.full {
            #{$box} {
                &__icon {
                    max-width: 100px;
                    margin-right: 40px;
                }
        
                &__info {
                    font-size: 1.125rem;
                }
        
                &__header {
                    font-size: 1.25rem;
                }
        
                &__form {
                    padding-left: 80px;
                }
            }
        }
    }
}