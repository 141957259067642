@use "../variables" as variable;

.cm-check-list {
    &__item {
        display: flex;
        margin-bottom: 20px;

        &::before {
            content: "";
            width: 18px;
            min-width: 18px;
            height: 18px;
            background-color: #97BF26;
            border-radius: 2px;
            margin-right: 20px;
            background-image: url(../../assets/checked-white.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 10px;
        }
    }
}