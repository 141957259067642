.cmsp-about {
    &__image {
        margin-bottom: 30px;
    }

    &__text {
        margin-bottom: 40px;
    }

    &__map {
        display: block;
        margin-bottom: 40px;
    }
}