@use "../../variables" as variable;

.wc-cart {
    &__content {
        margin-bottom: 40px;
    }
}


@include variable.media(1200px, min) {
    .wc-cart {
        display: flex;
        align-items: flex-start;
    
        &__content {
            padding-right: 16px;
            width: 66%;
            margin-bottom: 0;
        }
    
        &__info {
            width: 34%;
        }
    }
}