@use "../../variables" as variable;

.cmsd-list {
    margin-bottom: 80px;

    &__item {
        border-radius: variable.$box_radius;
        border: 1px solid variable.$grey;
        margin-bottom: 14px;
        padding: 30px 40px;
    }

    &__title {
        color: variable.$secondary;
        margin-bottom: 26px;
    }
}