@use "../variables" as variable;

.select2-container--default .select2-selection--single {
    height: 51px;
    border-radius: 23px;
    border: 1px solid #dedede;
    display: flex;
    align-items: center;
}


.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 51px;
    position: absolute;
    top: 0;
    right: 20px;
    width: 20px;
    background-image: url(../../assets/arrow-down.svg);
    background-repeat: no-repeat;
    background-position: center;

    b {
        display: none;
    }
}


.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 30px;
}


.select2-container--default .select2-selection--single .select2-selection__placeholder {
    font-size: .875rem;
    color: variable.$primary;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: variable.$secondary;
}

.select2-dropdown {
    border: 1px solid #dedede;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
}

.select2-results__option {
    padding: 10px 30px;
    font-size: .875rem;
}

.select2-container--default .select2-results__option--disabled {
    display: none;
}


select {
    width: 100%;
    max-width: 400px;
    height: 50px;
    border: 1px solid #bbbbbb;
    border-radius: 50px;
    padding: 0 30px;
    background-image: url(../../assets/arrow-down.svg);
    background-repeat: no-repeat;
    background-position: center right 30px;
    background-size: 10px 8px;
}