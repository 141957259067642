@use "../../variables" as variable;

.tp {
    background-color: #F6F6F6;
    padding-top: 240px;
    margin-top: -260px;
    padding-bottom: 100px;
    margin-bottom: 100px;

    &__header {
        text-align: center;
        margin-bottom: 50px;
    }
}

.tp-list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 -20px;

    &__item {
        width: 100%;
        padding: 0 10px;
        display: flex;
        margin-bottom: 20px;

        @include variable.media(1200px, min) {
            width: 33.33%;
        }
    }
}


.tp-about {
    max-width: 1200px;
    margin: 0 auto;
}