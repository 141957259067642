@use "../../variables" as variable;

.cm-declaration-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    margin: 0 -7px;

    &__item {
        display: flex;
        width: 100%;
        max-width: 574px;
        padding: 0 7px;
        margin-bottom: 14px;

        @include variable.media(1200px, min) {
            width: 33.33%;
            max-width: 574px;
        }
    }
}


.btn-declaration {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 30px;
    background-color: #ffffff;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    font-size: 1.25rem;
    letter-spacing: 0.05em;
    line-height: 1.5em;
    font-weight: 500;
    text-align: left;

    &:hover {
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    }

    span {
        max-width: 340px;
    }

    svg {
        width: 26px;
        min-width: 26px;
        margin-left: 30px;
    }

    &.active {
        color: variable.$secondary;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    }
}