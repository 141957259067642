@use "../../variables" as variable;

.page404 {
    padding-top: 60px;

    &__header {
        span {
            margin-right: 20px;
        }
    }

    &__desc {
        padding-top: 40px;
    }

    &__search {
        margin-bottom: 30px;
    }

    &__title {
        margin-bottom: 20px;
    }

    &__list {
        max-width: 680px;
        margin-bottom: 30px;

        li {
            margin-bottom: 14px;
        }
    }
}


@include variable.media(1199px, max) {
    .page404 {
        &__image {
            margin-top: 40px;

            img {
                margin: 0 auto;
            }
        }
    }
}


@include variable.media(1200px, min) {
    .page404 {
        &__row {
            display: flex;
        }

        &__image {
            width: 50%;
            order: 2;
        }
    
        &__desc {
            width: 50%;
            padding-top: 40px;
            padding-right: 80px;
        }
    }
}