@use "../variables" as variable;

.social-media {
    display: flex;
    justify-content: flex-end;
    margin: 0 -5px;

    &__item {
        padding: 0 5px;
    }

    &__link {
        width: 62px;
        height: 62px;
        min-width: 62px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: 1px solid variable.$secondary;

        &:hover {
            background-color: variable.$light;
            border-color: variable.$light;

            svg path {
                fill: #ffffff;
            }
        }
    }
}