@use "../variables" as variable;

.cm-breadcrumbs {
    padding: 28px 0;
    background-image: linear-gradient(92deg, #c71066 0%, #ed6ea6 100%);
    font-size: .75rem;
    font-weight: 300;
    color: #fc8ebe;
    margin-bottom: 30px;

    @include variable.media(576px, min) {
        margin-bottom: 60px;
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        color: #ffffff;

        @include variable.media(576px, min) {
            margin-bottom: 12px;
        }
    }

    &__info {
        font-size: 1rem;
        line-height: 1.5em;
        font-weight: 300;
        padding-left: 18px;

        @include variable.media(767px, max) {
            width: 100%;
            padding-left: 0;
        }

        @include variable.media(576px, min) {
            font-size: 1.25rem;
            line-height: 1.75em;
        }

        @include variable.media(1200px, min) {
            font-size: 2rem;
            line-height: 1.375em;
        }
    }

    ol {
        display: flex;

        @include variable.media(575px, max) {
            display: none;
        }

        li {
            margin-right: 2px;

            & > a {
                text-decoration: underline;
            }

            &::after {
                content: "/";
                display: inline-block;
                padding: 0 4px;
            }

            &:last-child {
                & > a {
                    text-decoration: none;
                }

                &::after {
                    content: none;
                }
            }
            
        }
    }
}